.faq-component {
  .global-props {
    @include desktop {
      width: 58.33333%;
    }
  }
}

.section-green,
.section-orange,
.section-yellow {
  .faq {
    border-color: $primary-dark !important;
  }
}

.faq-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  .faq {
    border-top: 1px solid $nav-stroke;
    flex: 0 0 100%;
    max-width: 100%;

    @include desktop {
      flex: 0 0 100%;
    }

    &:last-of-type {
      border-bottom: 1px solid $nav-stroke;
    }

    button {
      @include fontSize(20px);

      align-items: center;
      color: $black-offset;
      display: flex;
      font-family: $museo-500;
      justify-content: space-between;
      overflow-wrap: break-word;
      padding: 20px 10px;
      text-transform: inherit;

      @include desktop {
        padding: 20px;
      }

      span {
        // width of icon, plus some padding
        max-width: calc(100% - 20px);
      }

      img {
        width: 14px;
      }
    }

    .answer {
      padding: 5px 10px 25px;

      @include desktop {
        padding: 5px 20px 25px;
      }

      > * {
        margin-top: 0;
      }
    }

    &.open {
      > button,
      > .anchor-wrapper button {
        color: $main;
      }
    }

    &.secondary {
      border: none;
      margin-left: 20px;

      button.secondary,
      .anchor-wrapper button {
        justify-content: flex-start;

        &:first-of-type {
          padding-top: 0;
        }

        img {
          margin-right: 10px;
        }
      }

      .answer {
        padding-left: 30px;

        @include desktop {
          padding-left: 70px;
        }
      }
    }
  }
}
