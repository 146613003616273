.triple-column-section {
  background-color: $white;
  padding: 20px 0;

  section,
  div[class^="section"] {
    padding: 0;
  }

  > .container-fluid {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;

    @include mobile {
      grid-template-columns: 1fr;
      padding-left: 0;
      padding-right: 0;
    }

    .column {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      @include mobile {
        padding: 0;
      }

      > div {
        padding-bottom: 40px;

        &:last-of-type {
          padding-bottom: 0;
        }

        &:only-of-type {
          height: 100%;

          section,
          .container-fluid {
            height: 100%;
          }
        }
      }
    }
  }
}
