.datepicker-container {
  .group {
    position: relative;
  }

  .calendar-icon {
    background-color: transparent;
    background-image: url($calendar-svg);
    background-size: 100% 100%;
    border: none;
    color: $main-blue;
    cursor: pointer;
    height: 24px;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;

    &:focus-visible {
      outline-offset: 2px;
    }
  }
}

.datepicker-dialog {
  --light-blue: hsl(216deg 80% 92%);

  background-color: $white;
  border: 3px solid $primary-dark;
  border-radius: 8px;
  clear: both;
  display: none;
  margin-top: 0.15em;
  padding: 0;
  position: absolute;
  width: 320px;
  z-index: 100;

  .header {
    align-items: center;
    background-color: $primary-dark;
    color: $white;
    cursor: default;
    display: flex;
    font-weight: bold;
    gap: 8px;
    justify-content: space-around;
    padding: 8px 10px;
    text-transform: uppercase;
  }

  h2 {
    border: none;
    color: $white;
    display: inline-block;
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-transform: none;
  }

  button {
    background: transparent;
    border-style: none;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  .prev-year,
  .prev-month,
  .next-month,
  .next-year {
    background-image: url($chevron-white-svg);
    background-size: 100% 50%;
    border-radius: 0;
    color: white;
    height: 24px;
    padding: 4px;
    transition: none;
    width: 24px;
  }

  .prev-month,
  .next-month {
    background-position: center;
    background-repeat: no-repeat;
  }

  .prev-year,
  .prev-month {
    transform: rotate(90deg);
  }

  .next-year,
  .next-month {
    transform: rotate(-90deg);
  }

  .prev-year:hover,
  .prev-month:hover,
  .next-month:hover,
  .next-year:hover {
    border-radius: 4px;
    outline: 1px solid white;
    outline-offset: 2px;
  }

  .prev-year:focus,
  .prev-month:focus,
  .next-month:focus,
  .next-year:focus {
    border-radius: 4px;
    outline: 2px solid white;
    outline-offset: 2px;
  }

  .dialog-ok-cancel-group {
    margin-bottom: 1em;
    margin-right: 1em;
    margin-top: 1em;
    text-align: right;
  }

  .dialog-ok-cancel-group button {
    background-color: var(--light-blue);
    border-radius: 5px;
    color: $black;
    font-size: 0.85em;
    margin-left: 1em;
    outline: none;
    padding: 6px;
    transition: none;
    width: 5em;
  }

  .dialog-button:focus {
    outline: 2px solid $black;
  }

  .dialog-button:hover {
    outline: 1px solid black;
  }

  .month-year {
    display: inline-block;
    text-align: center;
    width: 12em;
  }

  .dates {
    width: 320px;
  }

  table.dates {
    border: none;
    border-collapse: separate;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
  }

  table.dates th,
  table.dates td {
    background: $white;
    border: none;
    color: $black;
    text-align: center;
  }

  table.dates tr {
    border: 1px solid $black;

    &[hide-last-row="true"] {
      display: none;
    }
  }

  table.dates td {
    background: $gray-light;
    border-radius: 5px;
    font-size: 15px;
    height: 40px;
    line-height: inherit;
    margin: 0;
    padding: 3px;
    width: 40px;
  }

  table.dates td.disabled {
    border: none;
    height: 41px;
    padding: 2px;
    width: 41px;
  }

  table.dates td:not(.disabled) {
    cursor: pointer;
  }

  table.dates td:focus,
  table.dates td:hover {
    background-color: var(--light-blue);
    color: black;
    padding: 0;
  }

  table.dates td:focus {
    border: 2px solid $gray-dark;
    outline: 0;
    padding: 1px;
  }

  table.dates td:not(.disabled):hover {
    border: 1px solid $gray-dark;
    padding: 2px;
  }

  table.dates td[aria-selected] {
    border: 2px dotted $gray-dark;
    padding: 1px;
  }

  table.dates td[aria-selected]:focus {
    border: 2px solid $gray-dark;
    padding: 1px;
  }

  table.dates td[tabindex="0"] {
    background-color: $main-blue;
    color: white;
  }

  .dialog-footer {
    background: $primary-dark;
    color: $white;
    height: 1em;
  }
}
