.horizontal-tile {
  border-radius: 20px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-out;
  width: 600px;

  @include deviceOnly {
    height: 300px;
    width: 100%;
  }

  &:hover {
    box-shadow: 0 10px 20px 3px rgb(0 0 0 / 0.4);
    text-decoration: none;
  }

  &-group {
    background-color: $white;
    contain: layout;
    padding-bottom: 3rem;
    padding-top: 2rem;

    .container-fluid {
      @include deviceOnly {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
    }
  }

  &-group-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    justify-content: center;
    padding-inline-end: 2.5rem;
    width: 600px;

    h2 {
      font-family: $museo-700;
      font-size: 3rem;
      line-height: 3rem;
      margin: 0;
    }

    p {
      font-family: $museo-300;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin: 0;
    }

    @include deviceOnly {
      width: 100%;
    }
  }

  &-image {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    transition: transform 0.3s ease;
    width: 100%;
    z-index: -10;

    .horizontal-tile:hover & {
      transform: scale(1.05);
    }
  }

  &-gradient {
    background: linear-gradient(to bottom, rgb(0 0 0 / 0), rgb(0 0 0 / 0.8));
    height: 150%;
    position: absolute;
    top: 0;
    transform-origin: top;
    transition: transform 0.3s ease;
    width: 100%;
    z-index: 1;

    .horizontal-tile:hover & {
      transform: scaleY(0.75);
    }
  }

  &-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
    top: 0;
    transition: top 0.3s ease;
    z-index: 2;

    .horizontal-tile:hover & {
      top: -1rem;
    }

    @include deviceOnly {
      gap: 1.25rem;
    }
  }

  &-header {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &-subheader {
    color: white;
    font-size: 1.125rem;
  }

  &-icon {
    border: 2px solid $main-yellow;
    border-radius: 100%;
    color: $main-yellow;
    display: grid;
    height: 3rem;
    place-items: center;
    transition: all 0.3s ease;
    width: 3rem;

    img {
      width: 1rem;
    }

    @include deviceOnly {
      height: 2.5rem;
      max-width: 2.5rem;
      width: 100%;
    }

    .horizontal-tile:hover & {
      background-color: $main-yellow;
      transform: rotate(-45deg);

      img {
        filter: brightness(0%);
      }
    }
  }
}
