.section-products-list {
  h2 {
    font-family: $sans-light;
  }

  .list {
    @include clear-list();

    @include desktop {
      display: flex;
      flex-wrap: wrap;

      .item {
        flex: 0 1 25%;
      }
    }

    .item {
      margin-bottom: 15px;
    }
  }
}
