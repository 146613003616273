@property --stat-value {
  inherits: false;
  initial-value: 0;
  syntax: '<integer>';
}

.statistic {
  --final-value: 0;

  border-radius: 2.5rem;
  color: $main;
  counter-reset: stat-value var(--stat-value);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 2.75rem 2.5rem;
  width: 100%;

  &:is(.section-green *),
  &:is(.section-orange *),
  &:is(.section-yellow *),
  &:is(.section-offset *),
  &:is(.section-white *) {
    border: 2px solid $main;
    color: $main;
  }

  &:is(.section-pink *),
  &:is(.section-blue *),
  &:is(.section-navy *) {
    border: 2px solid $white;
    color: $white;
  }

  &.counting {
    --stat-value: var(--final-value);
  }

  & span:first-of-type::before {
    content: counter(stat-value);
  }

  &-value {
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 1;
  }

  &-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }
}
