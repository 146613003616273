$breakpoint-tablet: $breakpoint-medium;
$breakpoint-desktop: $breakpoint-large;
$breakpoint-desktop-large: $breakpoint-extra-large;
$breakpoint-device-only: $breakpoint-desktop - 1px;
$breakpoint-mobile: $breakpoint-tablet - 1px;

@mixin mobile {
  @media screen and (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin deviceOnly {
  @media screen and (max-width: #{$breakpoint-device-only}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$breakpoint-device-only}) and (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin tabletAndDesktop {
  @media screen and (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin desktopLarge {
  @media screen and (min-width: #{$breakpoint-desktop-large}) {
    @content;
  }
}
