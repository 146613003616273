.section-intro {
  margin: 0 -10px;

  @include desktop {
    display: flex;
  }

  &.right {
    @include desktop {
      flex-direction: row-reverse;

      .media {
        justify-content: flex-start;
      }
    }
  }

  h2 {
    font-family: $sans-light;
    margin: 0 0 20px;
  }

  .content {
    padding: 0 10px;

    @include desktop {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }

    &-right {
      border-radius: 25px;
      display: flex;
      justify-content: flex-start;
      padding: 0 10px;

      .position-relative {
        position: relative;
      }

      @include desktop {
        border-radius: 50px;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      &.media {
        justify-content: center;
      }

      &.restrict-height {
        justify-content: center;
      }

      p {
        margin-bottom: 0;
      }

      button {
        display: grid;
        height: max-content;
        place-items: center;
        position: relative;
      }

      img {
        align-self: flex-start;
        border-radius: 50px;
        width: 100%;

        @include deviceOnly {
          border-radius: 25px;
        }
      }

      img.restrict-height {
        max-height: 293px;
        width: auto;
      }
    }
  }

  // Styling extended for detailed content component
  .media {
    padding: 0 10px;
    position: relative;

    @include desktop {
      display: flex;
      flex: 0 0 41.66667%;
      justify-content: flex-end;
      max-width: 41.66667%;
    }

    button {
      position: relative;
    }

    img,
    a {
      width: 100%;
    }
  }

  .quick-links {
    @include clear-list();
    @include fontSize(20px);

    @include deviceOnly {
      margin-top: 50px;
    }

    li {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        line-height: 28px;
      }

      .anchor-link {
        text-decoration: underline;
      }

      .menu-header {
        @include fontSize(20px);
      }
    }
  }
}
