.hero-small {
  --background-color: #{$offset};
  --text-color: #{$black};
  --slide-text-color: #{$black};
  --slide-justify-content: flex-start;
  --slide-text-align: left;
  --button-justify-content: flex-end;
  --button-text-color: inherit;
  --hero-main-green: #{$main-green};
  --hero-main-pink: #{$main-pink};
  --hero-main-blue: #{$main-blue};
  --hero-main-orange: #{$main-orange};
  --hero-main-yellow: #{$main-yellow};
  --hero-main-white: #{$white};
  --hero-main-offset: #{$offset};
  --hero-main-navy: #{$main};
  --hero-text-black: #{$black};
  --hero-text-white: #{$white};

  .color-wrapper {
    background-color: var(--background-color);
    color: var(--text-color);
    display: flex;
    min-height: 100px;
    padding: 50px 0 20px;

    &:is(.client-layout .color-wrapper) {
      padding: 165px 0 20px;
    }

    @include desktop {
      padding: 80px 0 35px;

      &:is(.client-layout .color-wrapper) {
        padding: 145px 0 35px;
      }
    }
  }

  .slide-content {
    color: var(--slide-text-color);
    justify-content: var(--slide-justify-content);
    text-align: var(--slide-text-align);
  }

  .content {
    align-self: flex-end;
    width: 100%;

    .btn-container {
      z-index: 1;

      &.second {
        @include mobile {
          margin-top: 15px;
        }
      }

      @include tabletAndDesktop {
        width: auto;
      }
    }
  }

  .container-fluid {
    display: block;

    @include tabletAndDesktop {
      display: flex;
    }
  }

  .inner-content {
    width: 100%;

    @include tabletAndDesktop {
      width: 66%;
    }

    h1 {
      @include fontSize(35px);

      line-height: 40px;

      @include desktop {
        @include fontSize(48px);

        line-height: 54px;
      }
    }
  }

  .inner-cta {
    align-self: flex-end;
    color: var(--button-text-color);
    display: flex;
    flex-grow: 1;
    gap: 5px;
    justify-content: var(--button-justify-content);

    @include mobile {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }

    @include deviceOnly {
      width: 100%;
    }
  }
}
