.section-icon-text {
  margin: 0 -10px;

  @include desktop {
    display: flex;
  }

  &.right {
    @include desktop {
      flex-direction: row-reverse;
    }
  }

  h2 {
    font-family: $sans-light;
    margin: 0 0 20px;
  }

  .content {
    padding: 0 10px;

    @include desktop {
      flex: 0 0 70%;
      max-width: 70%;
    }

    &-right {
      display: flex;
      justify-content: flex-start;
      padding: 0 10px;

      @include desktop {
        flex: 0 0 30%;
        max-width: 30%;
      }

      img {
        align-self: center;
        width: 100%;
      }
    }
  }

  // Styling extended for detailed content component
  .media {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    @include desktop {
      flex: 0 0 30%;
      max-width: 30%;
    }

    button {
      position: relative;
    }

    img,
    a {
      // stylelint-disable-next-line
      aspect-ratio: 1 / 1;
      width: 195px;
    }
  }
}
