.claims {
  .global-props {
    div {
      font-family: $sans-semibold;
    }
  }

  .online-section {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include desktop {
      flex-direction: row;
    }
  }

  .images-section {
    @include desktop {
      flex: 41.66667%;
      margin-left: 30px;
    }

    div {
      align-items: center;
      display: flex;

      img {
        display: inline-block;
        max-width: 50px;
        vertical-align: middle;
      }

      p {
        display: inline-block;
        margin-left: 20px;
      }
    }
  }

  .description-section {
    align-items: center;
    display: flex;

    @include desktop {
      flex: 0 0 58.33333%;
    }

    a {
      display: inline-block;
      margin: 20px 0;

      &:hover {
        text-decoration: none;
      }
    }

    h2 {
      margin-top: 0;
    }

    .info {
      div > p {
        margin-top: 0;
      }
    }

    .btn-no-style {
      color: $primary;
      display: block;
      margin-top: 20px;
      text-transform: inherit;
      width: auto;

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }

    .play {
      height: 30px;
      padding-right: 5px;
      vertical-align: middle;
    }

    .how-to {
      text-decoration: underline;
      vertical-align: middle;
    }
  }
}

.file-process-title {
  font-family: $sans-light;
}

.online-claims {
  .content-divider {
    background-color: $white;
  }
}
