// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: $breakpoint-small,
  md: $breakpoint-medium,
  lg: $breakpoint-large
) !default;
@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: $breakpoint-small - 36,
  md: $breakpoint-medium - 48,
  lg: $breakpoint-large - 32
) !default;
@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;
$enable-grid-classes: true !default;
