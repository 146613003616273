.client-layout {
  .nav {
    padding: 10px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    @include desktop {
      padding: 0;
    }

    &-wrap {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding-bottom: 5px;

      @include desktop {
        padding-bottom: 0;
      }
    }

    a {
      @include fontSize(18px);
      @include transition(0.2s ease);

      cursor: pointer;
      padding-bottom: 5px;
      text-transform: uppercase;
    }

    .inner {
      position: relative;

      > .container-fluid {
        padding: 20px 10px 0;

        &:not(.client-landing) {
          background: $primary-dark;
          border-radius: 0 0 50px 50px;
          padding: 0 45px 0 30px;
        }

        &:not(.client-listing-landing) {
          height: 105px;
          max-height: 105px;
          min-height: 105px;
        }
      }
    }

    .btn-contain {
      @include transition(border-bottom 0.2s ease);

      border-bottom: 2px solid transparent;
      display: inline-block;

      @include deviceOnly {
        &.has-link.no-children {
          border-bottom: 2px solid $primary-dark;
          color: $primary-dark;
          cursor: pointer;
          margin: 15px 20px;
          padding: 0;
        }
      }

      @include desktop {
        padding: 0 0 5px;

        a {
          text-decoration: none;

          &:hover,
          &:focus,
          &:visited,
          &:active {
            text-decoration: none;
          }
        }
      }
    }

    .wh-logo {
      @include desktop {
        margin: auto 0;
      }

      img {
        cursor: pointer;
        width: 100%;
      }

      a {
        display: inline-block;
        padding: 0;
      }
    }

    .primary-navigation-wrapper {
      background-color: $white;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 65px);
      justify-content: space-between;
    }

    ul {
      @include clear-list();

      a,
      span {
        display: block;
        padding: 15px 20px;

        @include desktop {
          padding: 0;
        }
      }

      li {
        @include deviceOnly {
          position: relative;

          &.active {
            color: $primary;

            .btn-contain {
              color: $main;

              &.has-link {
                border-bottom: 2px solid $main;
                cursor: pointer;
                margin: 15px 20px;
                padding: 0;
              }

              a,
              span,
              p.active {
                color: $main;
              }
            }
          }
        }

        &.active {
          > .btn-contain {
            border-bottom: 2px solid $white;
          }
        }

        a,
        p {
          display: block;
          padding: 15px 20px;

          @include desktop {
            padding: 0;
          }

          &.search {
            padding: 0 0 5px;
          }
        }

        p {
          color: $primary;
          margin: 0;

          @include fontSize(18px);
        }

        &.search {
          display: block;
          margin-right: 25px;
          text-align: right;

          @include desktop {
            margin-right: 0;

            img {
              width: 24px;
            }
          }
        }

        @include desktop {
          border-bottom: none;
          display: flex;
          flex-grow: 1;
          flex-wrap: wrap;
          text-align: center;

          &:hover {
            .btn-contain {
              border-bottom: 2px solid $white;
            }

            .triangle {
              opacity: 1;
              transition: opacity 0.2s ease-out 0.1s;
            }
          }

          .mega-menu {
            opacity: 1;
          }
        }

        img.chevron {
          position: absolute;
          right: 23px;
          top: 25px;
          width: 15px;
        }
      }

      &.nav-cta {
        align-items: center;
        display: flex;
        flex: 0 0 70px;
        justify-content: flex-end;
        padding-top: 5px;

        a,
        i {
          @include fontSize(30px);

          color: $primary;
          cursor: pointer;
        }

        img {
          width: 20px;

          @include desktop {
            width: auto;
          }
        }

        li {
          border-bottom: none;
          padding: 0;
        }
      }
    }

    ul.primary-navigation {
      span {
        @include fontSize(18px);
        @include transition(0.2s ease);

        color: $white;
        cursor: default;
        font-family: $sans-bold;
        text-decoration: none;
        text-transform: uppercase;

        a {
          color: $white;
        }
      }

      @include deviceOnly {
        background-color: $white;
      }

      @include desktop {
        display: flex;
        flex-grow: 1;
      }
    }

    ul.secondary-navigation {
      @include desktop {
        background-color: $offset;
      }

      li {
        margin: 0 15px 0 40px;

        &:last-of-type {
          border-bottom: none;
        }

        img.chevron {
          right: 8px;
        }

        .btn-contain {
          padding: 0;
        }
      }
    }
  }

  // Using double selector to override the triple class specificity of .nav.relative.inverse elsewhere.
  .nav.white-label.white-label {
    background-color: transparent;

    @include deviceOnly {
      margin-bottom: 0;
      position: absolute;
      top: 0;
      transition: none;
    }

    @include desktop {
      padding-top: 0;
      top: -110px;
    }
  }

  .alerts {
    .nav {
      &.relative {
        background-color: $main;
        margin-bottom: -190px;
        top: -190px;

        @include desktop {
          margin-bottom: -210px;
          top: -210px;
        }
      }

      &.expanded {
        position: fixed;
        top: 0;
      }

      &.white-label {
        background-color: transparent;
        margin-bottom: -255px;
        top: -190px;

        @include deviceOnly {
          position: relative;
        }
      }
    }
  }

  .client-mobile-logo {
    max-width: 200px;
    width: 100%;
  }

  .client-sm-no-border {
    border: none !important;
    padding: 5px;
  }

  .client-layout {
    @include deviceOnly {
      padding-top: 3rem;
    }
  }
}
