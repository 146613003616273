/* stylelint-disable selector-class-pattern */

// Base elements
html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  width: 100%;
}

*,
*::before,
*::after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  @include fontSize(16px);

  background: $offset;
  color: $text-body;
  font-family: $sans-medium;
  font-weight: 300;
  left: 0;
  line-height: 24px;
  position: relative;

  &.preview-mode {
    padding-top: 0 !important;
  }

  &.nav-open {
    overflow: hidden;
  }
}

// End base elements

[id^='react_'] {
  display: contents;
}

a {
  &:focus {
    outline: none;
  }
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

hr {
  @include clearfix;

  background: $gray-dark;
  border: 0;
  height: 1px;
  margin: 40px 0 30px;
}

img {
  height: auto;
  max-width: 100%;
}

i,
em,
.italic {
  font-family: $sans-medium-italic;
  font-style: italic;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

li {
  &.inline {
    display: inline-block;

    a {
      display: inline-block;
    }
  }
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5px;
}

object,
embed,
audio,
video {
  max-width: 100%;
}

p {
  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

strong,
b,
.bold {
  font-family: $sans-bold;
  font-weight: bold;
}

ul ul,
ol ul {
  list-style-position: inside;
  list-style-type: circle;
}

ul ol,
ol ol {
  list-style-position: inside;
  list-style-type: lower-latin;
}

ul ul ul,
ul ol ul,
ol ul ul,
ol ol ul {
  list-style-position: inside;
  list-style-type: square;
}

ul ul ol,
ul ol ol,
ol ul ol,
ol ol ol {
  list-style-position: inside;
  list-style-type: lower-roman;
}

.center {
  text-align: center;
}

.img-placeholder {
  --image-ratio: 0%;

  padding-top: var(--image-ratio);
  width: 100%;
}

/** Start- CSP Global Classes For Admin **/
.inline-red {
  color: #fe3b1f;
}

.inline-indigo {
  color: #211261;
}

.inline-floatLeft {
  float: left;
  padding-top: 4px;
}

.inline-VBShareImg {
  float: left;
  margin: 0 14px 0 0;
  width: 50px;
}

.inline-center {
  text-align: center;
}

.inline-left {
  text-align: left;
}

.inline-right {
  text-align: right;
}

.inline-listCircle {
  list-style-type: circle;
}

.inline-SBBAlert {
  background-color: #ffbf3c;
  padding: 10px 16px;
  width: 80%;
}

.inline-SBBFlyer {
  border: 1px solid #cdcacc;
  width: 50%;
}

.inline-SBBImageSize {
  height: 250px;
  width: 200px;
}

.material-items {
  display: flex;
}

.material-items img {
  max-width: 120px;
}

.w-auto {
  width: auto;
}

@media only screen and (max-width: 600px) {
  .material-items {
    display: block;
    text-align: center;
  }

  .material-items img {
    max-width: 260px;
  }

  .material-item-component .item-container .item .inner .text {
    padding-left: 50px !important;
    padding-right: 50px !important;
    text-align: left !important;
  }

  .material-item-component .item-container .item .inner .desc {
    margin-top: 0.5rem !important;
    padding-left: 55px !important;
    padding-right: 55px !important;
    text-align: left !important;
  }

  .material-item-component .item-container .item .inner {
    text-align: center;
  }
}

/** End- CSP Global Classes For Admin **/
