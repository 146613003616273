.social-footer {
  margin: 0 auto;
  max-width: 420px;
  text-align: center;

  @include tabletAndDesktop {
    max-width: 720px;
  }

  h2 {
    margin: 0;
  }

  ul {
    margin: 34px 0 0;
    padding: 0;

    @include tabletAndDesktop {
      margin-top: 40px;
    }

    li {
      margin: 0 14px;

      img {
        width: 60px;
      }
    }
  }
}
