// Hero conditional styles
.video-wrapper {
  left: 0;
  overflow: hidden;
  padding-bottom: 41.67%;
  pointer-events: none;
  position: relative;
  top: 0;
  width: 100%;

  @media screen and (max-width: 700px) {
    padding-bottom: 142.85%;
  }

  a {
    pointer-events: all;
  }

  iframe {
    display: block;
    height: 56.25vw;
    left: 50%;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @include mobile {
      display: none;
    }
  }
}

.color-wrapper {
  padding-bottom: 142.85%;

  @include tabletAndDesktop {
    padding-bottom: 50%;
  }

  @include desktop {
    padding-bottom: 34.72%;
  }
}

.hero {
  text-align: left;

  .container-fluid {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;

    &.button {
      @include mobile {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start !important;
      }
    }

    @include desktop {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .inner-content {
    h1,
    h2 {
      @include fontSize(40px);

      color: inherit;
      font-family: $sans-bold;
      font-weight: 300;
      margin: 0;

      @include desktop {
        @include fontSize(48px);
      }
    }

    p {
      line-height: 24px;
      margin: 10px 0 0;

      @include desktop {
        @include fontSize(20px);
      }
    }
  }

  .content {
    z-index: 1;

    .btn-container {
      --cta-button-background-color: #{$main-pink};
      --cta-button-text-color: #{$white};

      display: inline-block;
      margin-top: 15px;
      position: relative;

      a {
        background-color: transparent;
        color: var(--cta-button-text-color);
        display: inline-block;
        height: 100%;
        width: 100%;
        z-index: 0;

        &::before,
        &::after {
          border-radius: 25px;
          content: '';
          display: block;
          height: inherit;
          left: 0;
          position: absolute;
          top: 0;
          width: inherit;
        }

        &::after {
          background: #0003;
          display: none;
          z-index: -50;
        }

        &::before {
          background-color: var(--cta-button-background-color);
          z-index: -100;
        }

        &:hover {
          text-decoration: none;

          &::after {
            display: block;
          }
        }
      }
    }
  }

  &:is(.client-layout .hero) {
    margin-top: -105px;
  }

  .button-wrapper {
    display: flex;
    gap: 20px;
  }
}
