.client-layout {
  .mega-menu {
    @include transition(opacity 0.2s ease);

    background: $white;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 126px;
    width: 100%;
  }

  .expansion {
    border: solid $nav-stroke;
    border-width: 1px 0;
    left: 0;
    position: relative;
    width: 100%;

    @include desktop {
      border-width: 2px 0;
      margin-top: 38px;
      position: absolute;
      top: 66px;
    }

    .heading {
      h2,
      .menu-heading {
        @include fontSize(18px);

        color: $main;
        font-weight: 700;
        line-height: 28px;
        margin: 0;
      }
    }

    .business-unit-wrapper {
      padding-bottom: 20px;
    }

    .business-unit-details {
      @include desktop {
        background-color: $gray-lightest;
        margin-top: -20px;
        padding: 30px;
      }

      a {
        padding: 10px 20px;
        text-transform: none;

        @include desktop {
          padding: 0;
        }

        &:last-of-type {
          padding-bottom: 30px;

          @include desktop {
            padding-bottom: 0;
          }
        }
      }

      .col-sm-6 {
        padding: 0 30px;
        text-align: left;

        a {
          background-color: transparent;
          border: none;
          color: $primary;
          margin: 4px 0;
        }

        h3 {
          margin-bottom: 6px;
          margin-top: 0;
          text-transform: uppercase;
        }

        p {
          color: $black;
        }
      }
    }
  }
}
