.full-width-image-carousel {
  padding-bottom: 10px;

  .wrapper {
    padding: 0 clamp(40px, 6vw, 60px);
    width: 100%;
  }

  .slick-slider {
    margin: 0;
  }

  .slick-arrow {
    height: auto;
    padding: 0.5rem;
    width: auto;
    z-index: 1;

    &::before {
      display: none;
    }

    &.slick-next {
      right: 0;
    }

    &.slick-prev {
      left: 0;
    }

    > img {
      height: clamp(30px, 5vw, 45px);
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;

    button {
      background: transparent;
      border: 0;
      color: transparent;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 12px;
      line-height: 0;
      outline: none;
      padding: 5px;
      width: 12px;

      &::before {
        background-color: transparent;
        border-color: $primary-dark;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        content: '';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 12px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        transform: translate(45%, 45%);
        width: 12px;
      }

      &:hover,
      &:focus {
        outline: none;

        &::before {
          opacity: 1;
        }
      }
    }

    li {
      display: grid;
      place-content: center;
    }

    li.slick-active {
      button {
        &::before {
          background: $primary-dark;
        }
      }
    }
  }

  .slick-list {
    height: 100%;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      border-radius: 25px;
      margin: 0 auto;

      @include desktop {
        border-radius: 50px;
      }
    }
  }
}
