.predictive-search-container {
  @include desktop {
    display: flex;
    justify-content: center;
  }

  .predictive-search {
    position: relative;

    @include desktop {
      flex: 0 1 66.66667%;
    }

    button {
      color: $primary;
      height: 18px;
      position: absolute;
      right: 30px;
      top: 22px;
      width: inherit;
      z-index: 3;

      &.close-icon {
        padding-right: 40px;
      }
    }
  }
}

.predictive-dropdown {
  background-color: $offset;
  border-radius: 0 0 1px 1px;
  box-shadow: 0 1px 2px 0 #00000080;
  padding: 50px 0 30px;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 1;

  .dropdown-item {
    color: $primary;
    cursor: pointer;
    font-family: $sans-bold;
    font-size: 20px;
    padding: 8px 30px;

    &:hover,
    &:focus {
      background-color: #ddd;
    }

    a {
      display: block;
      text-decoration: none;
    }

    p {
      margin: 0;
    }

    .url {
      @include fontSize(14px);

      color: #7b868c;
      margin: 0;
    }
  }
}

.predictive-search-text {
  @include fontSize(25px);

  text-align: center;
}

.highlight {
  --number-background-color: $white;
  --number-text-color: $main;

  background-color: $highlight;

  .number {
    background-color: var(--number-background-color);
    color: var(--number-text-color);
  }
}
