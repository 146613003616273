.data-table {
  &-row {
    border-bottom: 2px solid $gray-light;

    @include desktop {
      display: flex;
    }

    &:last-of-type {
      border-bottom: none;
    }

    .data-row {
      padding: 20px 20px 0 0;

      @include desktop {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      }

      @include deviceOnly {
        .section-header {
          @include fontSize(25px);

          display: block;
          margin-bottom: 20px;
        }
      }
    }

    .data-section {
      padding: 0 0 15px;

      @include desktop {
        border-left: 2px solid $gray-light;
        flex: 0 0 41.66667%;
        justify-content: center;
        max-width: 41.66667%;
        padding: 15px 0 15px 15px;
      }

      p {
        margin: 0;
      }

      .section-item {
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .sub-section {
          span {
            display: block;
          }

          .label {
            flex: 0 1 25%;
            font-family: $sans-bold;
          }

          &.link {
            margin: 0 0 10px;
          }

          @include desktop {
            display: flex;

            &.full {
              display: block;

              span {
                display: inherit;
              }
            }

            p {
              flex: 0 1 75%;
            }
          }

          b {
            color: $black-offset;
          }
        }
      }
    }

    &:first-of-type {
      .data-row,
      .data-section {
        padding-top: 0;
      }
    }

    .section-header {
      @include fontSize(20px);

      line-height: 24px;
    }
  }
}
