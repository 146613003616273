.search-landing {
  .search-section {
    padding: 40px 0;
  }

  .filters-list {
    border-bottom: none;
  }

  .filters-container {
    @include desktop {
      border-top: 2px solid $gray-light;
    }

    .accordion-list {
      @include desktop {
        margin: 0;
      }

      ul.accordion {
        @include desktop {
          &.sort {
            position: relative;

            .expanded-filters-list {
              background-color: $gray-light;
              position: absolute;
              width: 100%;
              z-index: 1;

              .filters-list {
                border: 1px solid $gray;
                border-top: none;
                display: block;
                padding: 20px 0;

                li {
                  border: none;

                  button {
                    color: $primary;
                    height: inherit;
                    margin: 0;
                    padding: 10px 20px;

                    &:hover {
                      color: $main;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .search-links {
    align-items: center;
    display: flex;
    flex-direction: column;

    a {
      @include fontSize(20px);

      line-height: 25px;
    }
  }

  .search-list {
    .result {
      margin-bottom: 60px;

      h2 {
        @include fontSize(30px);

        color: $primary;
        font-family: $sans-bold;
        margin: 0;
      }

      .btn-no-style {
        text-transform: unset;

        p {
          color: $primary;
          font-family: $sans-bold;
        }
      }

      .tag {
        @include fontSize(12px);

        background-color: $gray-light;
        color: $gray-dark;
        padding: 3px 6px;
      }

      .url {
        @include fontSize(14px);

        color: #7b868c;
        font-family: $sans-bold;
        margin: 0;
      }
    }

    .no-results {
      br {
        color: transparent;
      }
    }
  }
}
