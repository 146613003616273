@use 'sass:math';

@mixin bg-cover() {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

@function calculate-rem($size) {
  /* stylelint-disable-next-line scss/dollar-variable-pattern */
  $remSize: math.div($size, 16px);

  @return #{$remSize}rem;
}

@mixin clearfix() {
  &::after {
    clear: both;
  }

  &::before,
  &::after {
    content: ' ';
    display: table;
  }
}

@mixin clear-list() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin fontSize($size) {
  font-size: $size; // Fallback in px
  font-size: calculate-rem($size);
}

@mixin placeholder($color, $font) {
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
    font-family: $font;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
    font-family: $font;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
    font-family: $font;
  }
}

// The mixin need to be applied to the container wrapping an img tag
// Orientation should equal either 'landscape' or 'portrait'
@mixin responsive-ratio($x, $y, $orientation) {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    /* stylelint-disable-next-line */
    padding-top: unquote(math.div($y, $x) * 100 + '%');
  }

  img {
    inset: 0;
    max-width: none;
    position: absolute;

    /* stylelint-disable-next-line scss/at-rule-conditional-no-parentheses */
    @if ($orientation == 'landscape') {
      width: 100%;
    } @else {
      height: 100%;
    }
  }
}

// example
// .test-image-container {
//     @include responsive-ratio(16,9,'landscape');
// }

@mixin tab-focus() {
  outline: none !important;
}

@mixin transition($args...) {
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  -webkit-transition: $args;
  transition: $args;
}
