.privacy-policy-component {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;

    .privacy-policy-container {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  h3 {
    color: $main;
  }

  button.to-top {
    bottom: 20px;
    opacity: 1;
    position: fixed;
    right: 15px;
    transition: 0.2s ease;
    z-index: 999;

    @include desktop {
      right: 100px;
    }

    &.btn-hidden {
      opacity: 0;
    }

    &.btn-relative {
      bottom: inherit;
      position: absolute;

      @include deviceOnly {
        margin-top: 15px;
      }
    }
  }

  .form-group {
    margin-top: 15px;

    &.select-input {
      max-width: 100%;
      width: max-content;

      &::after {
        top: 25px;
      }

      select {
        @include fontSize(18px);

        height: 60px;
        padding: 10px 50px 10px 20px;
      }
    }
  }
}
