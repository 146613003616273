.material-search-landing {
  --brand-color: '';
  --button-color: '';

  .container-fluid {
    padding: 20px 10px;
  }

  .listing {
    display: grid;
    gap: 4rem;
    grid-template-columns: 15rem 1fr;

    @include deviceOnly {
      grid-template-columns: 1fr;
    }
  }

  .filter-drawer-wrapper {
    @keyframes visibility-hidden {
      0% { visibility: visible; }
      99% { visibility: visible; }
      100% { visibility: hidden; }
    }

    @include deviceOnly {
      height: 100dvh;
      left: 0;
      position: fixed;
      top: 0;
      transition: background 0.5s ease-in-out;
      width: 0;
      width: 100dvw;
      z-index: 1000;

      &:has(.expanded) {
        background-color: rgb(0 0 0 / 0.5);
      }

      &:not(:has(.expanded)) {
        animation: visibility-hidden 0.5s forwards;
      }

      & > :first-child {
        background-color: $white;
        height: 100dvh;
        left: 0;
        overflow: hidden auto;
        position: absolute;
        right: 100%;
        top: 0;
        transition: all 0.5s ease-in-out;
        white-space: nowrap;
        z-index: 10;

        &.expanded {
          box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
          right: 15%;
        }

        &:not(.expanded) {
          animation: visibility-hidden 0.5s forwards;
        }
      }
    }
  }

  .filters {
    @include deviceOnly {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > * {
        margin: 0 16px;
      }
    }

    .clear {
      @include fontSize(16px);

      background: transparent;
      border: 2px solid $main-green;
      color: $black;
      margin-top: 4px;
      min-width: 200px;
      width: 100%;

      &:is(.client-layout *) {
        border: 2px solid var(--button-color);
        font-weight: 900;
      }

      &:hover {
        background-color: transparent;
      }

      &:disabled {
        border-color: hsl(var(--green) / 0.7);
        color: $gray;

        &:is(.client-layout *) {
          border: 2px solid color-mix(in srgb, var(--button-color) 70%, transparent);
        }
      }
    }

    .show-all {
      color: $main-blue;
      margin-top: 8px;
      text-transform: none;
      width: max-content;

      &:is(.client-layout *) {
        color: var(--brand-color);
        font-weight: 900;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .filter {
      border-top: 2px solid $primary-dark;
      margin-top: 24px;

      h3 {
        margin: 8px;
        text-transform: uppercase;
      }
    }

    ol {
      @include clear-list();

      li {
        border-bottom: 1px solid $gray-light;
        display: flex;
        padding: 8px;

        label {
          cursor: pointer;
          flex-grow: 1;
        }

        input[type="checkbox"] {
          flex-shrink: 0;
          margin-right: 8px;
        }
      }
    }

    .mobile-header {
      background: $white;
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      position: sticky;
      top: 0;
      z-index: 30;

      h2 {
        @include fontSize(24px);

        margin: 0;
      }

      .close-filters {
        display: grid;
        place-items: center;
        width: 24px;
      }

      @include desktop {
        display: none;
      }
    }

    .mobile-footer {
      background: $white;
      bottom: 0;
      padding: 20px 0;
      position: sticky;
      z-index: 30;

      .apply {
        @include fontSize(16px);

        background: $main-green;
        color: $black;
        min-width: 200px;
        width: 100%;

        &:is(.client-layout *) {
          background: var(--button-color);
        }
      }

      @include desktop {
        display: none;
      }
    }

    &.shadow-start .mobile-header::before,
    &.shadow-end .mobile-footer::before {
      background: white;
      box-shadow: 4px 0 12px 0 rgb(0 0 0 / 0.25);
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: calc(100% + 32px);
      z-index: -1;
    }
  }

  .results {
    align-content: start;
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr;

    @include deviceOnly {
      grid-template-columns: 1fr;
    }

    h2 {
      margin: 0;

      @include deviceOnly {
        margin-top: 8px;
      }
    }

    .pre {
      grid-column: span 2;

      @include deviceOnly {
        grid-column: span 1;
      }

      .show-filters {
        background: $main-green;
        color: $black;
        width: 240px;

        &:is(.client-layout *) {
          background: var(--button-color);
        }

        @include mobile {
          width: 100%;
        }

        @include desktop {
          display: none;
        }
      }
    }

    .no-results {
      grid-column: span 2;

      @include deviceOnly {
        grid-column: span 1;
      }

      p {
        @include fontSize(24px);
      }
    }
  }
}
