.highlights-component {
  --flex-percent: 33.3333%;

  .highlights-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.margin {
      margin-top: 15px;
    }

    @include mobile {
      align-items: center;
      flex-direction: column;
    }
  }

  .highlight {
    --number-background-color: $main;
    --number-text-color: $white;

    background-color: transparent;
    flex: 0 1 calc(var(--flex-percent) - 70px);
    margin: 0 30px;
    text-align: center;

    @include mobile {
      margin: 15px 30px;
    }

    a {
      text-transform: uppercase;
    }

    h3 {
      margin-bottom: 0;
    }

    h4 {
      @include fontSize(16px);

      font-family: $sans-light;
      text-align: center;
      text-transform: none;
    }

    img {
      max-height: 100px;
      max-width: 100px;
    }

    .img-placeholder {
      height: 100%;
      max-height: 100px;
      padding-top: 0 !important;
    }

    .number {
      @include fontSize(40px);

      background-color: var(--number-background-color);
      border-radius: 50px;
      color: var(--number-text-color);
      display: inline-block;
      font-family: $sans-bold;
      height: 100px;
      line-height: 36px;
      padding: 32px;
      width: 100px;
    }
  }

  &.predictive {
    margin-top: 60px;

    .highlight {
      align-items: center;
      display: flex;
      flex-direction: column;

      .number,
      .step-image {
        margin: 20px auto;
        max-width: 300px;
      }
    }
  }
}
