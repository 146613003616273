.section-simple-products-services {
  --slide-flex-basis: 25%;

  position: relative;

  .close-button {
    margin-right: 45px;

    &.no-slider {
      margin-right: 0;
      margin-top: 30px;
    }
  }

  .heading {
    h2 {
      margin: 0 0 20px;
      text-align: center;
    }

    .content-right {
      align-self: flex-end;
      display: none;
      text-align: right;

      @include desktop {
        display: block;
      }

      img {
        height: 25px;
        margin-bottom: 2px;
      }
    }

    p {
      color: $gray-dark;
      font-weight: normal;
    }
  }

  .slick-track {
    display: flex;
    width: 100% !important;

    &::before {
      display: none;
    }

    .slick-slide {
      border-radius: 50px;
      display: flex;
      flex: 1 0 100%;
      height: auto;
      transition: background-color 0.1s ease-out;

      @include tablet {
        flex: 1 0 50%;
      }

      @include desktop {
        flex: 1 0 var(--slide-flex-basis);
      }

      > div {
        border-radius: 50px;
        display: flex;
        flex: 100%;
        padding: 0 10px;
      }

      .tile {
        flex: 0 0 100%;
        margin: 0;

        &.active {
          background-color: $primary-dark;
        }

        .content {
          padding: 24px 20px 19px;
          text-align: center;
          transition: background-color 0.1s ease-out;

          p {
            font-family: $sans-medium;
            margin: 5px 0;
            text-transform: none;
          }
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  .simple-tiles {
    display: block;
    margin: 30px 40px 0;
    margin-top: 30px;
    position: relative;

    &.no-slider {
      display: flex;
      margin: 30px -10px 0;
    }

    .navigation {
      align-items: center;
      display: flex;
      height: 100%;
      position: absolute;
      top: 0;
      width: auto;

      &#next-arrow,
      &#previous-arrow {
        background-color: transparent;
      }

      &#next-arrow {
        color: $primary-dark;
        right: -40px;
      }

      &#previous-arrow {
        color: $primary-dark;
        left: -40px;
      }

      img {
        color: $primary;
        height: 45px;
      }
    }
  }
}
