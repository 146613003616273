a {
  color: $text-link;
  font-family: $sans-bold;

  &:hover {
    text-decoration: underline;
  }
}

// subfooter links
.subfooter-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.subfooter-links a {
  color: $white;
  margin-right: 15px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $white;
  }
}

h1,
h2 {
  color: $main;
}

h3,
h4,
h5,
h6 {
  font-family: $sans-bold;
}

h1,
h1 > span {
  @include fontSize(40px);

  font-family: $museo-700;
  line-height: 50px;

  @include desktop {
    @include fontSize(48px);

    line-height: 54px;
  }
}

h2,
h2 > span {
  @include fontSize(30px);

  font-family: $museo-500;
  line-height: 38px;

  @include desktop {
    @include fontSize(32px);

    line-height: 44px;
  }
}

h3,
h3 > span {
  @include fontSize(20px);

  font-family: $museo-300;
  line-height: 28px;
}

h4,
h4 > span {
  @include fontSize(16px);

  font-family: $museo-300;
  letter-spacing: 0.7px;
  line-height: 20px;
  text-transform: uppercase;
}

h5,
h5 > span {
  @include fontSize(16px);

  color: $black;
  font-family: $museo-300;
  line-height: 20px;
}

.menu-header,
.eyebrow-text,
.section-header {
  @include fontSize(16px);

  font-family: $sans-bold;
  letter-spacing: 0.7px;
  line-height: 20px;
  text-transform: uppercase;
}

.eyebrow-text {
  letter-spacing: 0;
  margin: 0;
}

.page-heading {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
  display: inline-block;
  font-size: 0;
  height: 1px;
  margin: 0 !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px;
}

.h-22 {
  height: 22px !important;
}

.pt-0 {
  padding-top: 0 !important;
}
