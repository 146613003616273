.homepage-hero {
  --hero-main-green: #{$main-green};
  --hero-main-pink: #{$main-pink};
  --hero-main-blue: #{$main-blue};
  --hero-main-orange: #{$main-orange};
  --hero-main-yellow: #{$main-yellow};
  --hero-main-white: #{$white};
  --hero-main-offset: #{$offset};
  --hero-main-navy: #{$main};
  --hero-text-black: #{$black};
  --hero-text-white: #{$white};
  --cta-button-background-color: #{$main-pink};
  --cta-button-text-color: #{$white};

  background-color: $white;
  overflow: hidden;

  &-container {
    background-color: $primary-dark;
    color: $white;
    padding-block: 1rem 3rem;
    position: relative;

    @include deviceOnly {
      padding-bottom: 2rem;
      padding-top: 1.25rem;
    }
  }

  &:has(.featured-ctas) {
    .homepage-hero-container {
      padding-bottom: 5rem;
    }

    @include deviceOnly {
      .homepage-hero-container {
        padding-bottom: 2.5rem;
      }
    }
  }

  .container-fluid {
    align-items: center;
    display: flex;
    gap: 3.75rem;

    @include deviceOnly {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  &-image {
    border-radius: 50px;

    @include deviceOnly {
      border-radius: 25px;
    }
  }

  &-content {
    display: grid;
    gap: 2rem;

    h1 {
      color: $white;
      font-family: $museo-300;
      font-size: 3.75rem;
      font-weight: 300;
      line-height: 4.5rem;
      margin: 0;

      @include deviceOnly {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }

    p {
      font-family: $museo-300;
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0;

      @include deviceOnly {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }

    .button-wrapper {
      display: flex;
      gap: 8px;

      .btn-container {
        a {
          background-color: transparent;
          color: var(--cta-button-text-color);
          line-height: 3rem;
          padding: 12px 28px;
          position: relative;
          text-decoration: none;
          text-transform: none;

          &::after {
            background-color: var(--cta-button-background-color);
            border-radius: 25px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: all cubic-bezier(0.4, 0, 0.2, 1) 300ms;
            width: 100%;
            z-index: -1;
          }

          &:hover::after {
            filter: brightness(0.8);
          }
        }
      }
    }
  }

  .fade-in-container:has(.homepage-hero-image) {
    max-width: 36rem;
    width: 100%;
  }

  .featured-ctas {
    bottom: 0%;
    display: flex;
    gap: 1.25rem;
    justify-content: center;
    left: 0;
    margin-bottom: -12px;
    position: relative;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;

    @include deviceOnly {
      gap: 8px;
      margin-bottom: -8px;
      transform: translateY(-24px);

      .featured-cta {
        @include fontSize(18px);

        padding: 18px 12px;
      }
    }
  }
}
