.vertical-dots {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;

  @include deviceOnly {
    bottom: 30px;
    left: 0;
    width: 100%;
  }

  @include desktop {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  li {
    cursor: pointer;
    display: inline-block;
    margin: 15px;
    position: relative;

    @include desktop {
      display: block;
    }

    button {
      background: transparent;
      border: 0;
      color: transparent;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 12px;
      line-height: 0;
      outline: none;
      padding: 5px;
      width: 12px;

      &::before {
        background-color: transparent;
        border-color: $white;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        content: '';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 12px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 12px;
      }

      &:hover,
      &:focus {
        outline: none;

        &::before {
          opacity: 1;
        }
      }
    }

    &.slick-active {
      button {
        &::before {
          background: $white;
        }
      }
    }
  }
}
