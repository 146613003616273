.tab-close {
  .btn-container {
    display: inline-block;
    margin-top: 10px;
    position: relative;
    z-index: 1;

    button {
      background-color: transparent;
      display: inline-block;
      height: 100%;
      width: 100%;
      z-index: 0;

      &::before,
      &::after {
        border-radius: 25px;
        content: '';
        display: block;
        height: inherit;
        left: 0;
        position: absolute;
        top: 0;
        width: inherit;
      }

      &::after {
        background: #0003;
        display: none;
        z-index: -50;
      }

      &::before {
        z-index: -100;
      }

      &:hover {
        text-decoration: none;

        &::after {
          display: block;
        }
      }
    }
  }
}
