.global-props {
  h1 {
    font-family: $museo-700;
    margin: 20px 0;
  }

  h2 {
    font-family: $museo-500;
    margin: 0 0 20px;
  }

  p {
    margin-top: 0;
  }

  div {
    font-family: $museo-300;
  }

  .content {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .parent-page-link {
    a {
      display: inline-block;
      margin-bottom: 10px;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;

      &:hover {
        text-decoration: underline;
      }
    }

    .chevron {
      margin-left: 5px;
      max-height: 18px;
      vertical-align: middle;
    }
  }
}
