.section-product-navigation {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;

  a {
    text-decoration: none;

    span {
      @include fontSize(16px);

      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }

      img {
        height: 12px;
      }
    }
  }

  h3 {
    @include fontSize(16px);

    color: $black-offset;
    margin: 0;
  }

  .next {
    margin: 0 0 0 auto;
    text-align: right;

    img {
      margin-left: 10px;
    }
  }

  .prev {
    margin: 0 auto 0 0;

    img {
      margin-right: 10px;
    }
  }
}
