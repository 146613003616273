footer {
  background-color: $main;
  padding-inline: 10px;

  .footer-container {
    color: $white;
    display: grid;
    gap: 24px;
    margin-inline: auto;
    max-width: 1600px;
    padding: 24px 22px;

    @media screen and (min-width: 1150px) {
      padding: 8px 12px 32px;
    }

    @include desktop {
      gap: 60px;
      grid-template-columns: repeat(4, 1fr);
      padding: 60px 8px;
    }

    @include desktopLarge {
      padding: 60px 20px;
    }
  }

  .main-logo {
    border-radius: 4px;
    display: inline-block;

    &:focus {
      outline: 2px solid $main-orange;
      outline-offset: 2px;
    }

    img {
      max-width: 186px;
    }
  }

  .social-icons {
    display: flex;
    gap: 12px;
    margin-top: 8px;

    .social-icon {
      height: 32px;
      width: 32px;
    }

    a:focus {
      border-radius: 4px;
      outline: 2px solid $main-orange;
      outline-offset: 2px;
    }
  }

  .footer-column {
    h3 {
      font-family: $museo-700;
      font-weight: 900;
      margin: 0 0 12px;
    }

    p {
      margin-block: 6px;
      white-space: pre;
    }

    ul {
      @include clear-list();

      margin: 0;

      li {
        margin-bottom: 8px;
        width: 100;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .fill-up-link {
    color: $white;
    display: inline-block;
    font-family: $museo-500;
    position: relative;
    text-decoration: none;
    text-wrap: balance;
    transition: color 0.3s ease-in-out;
    width: max-content;
    z-index: 1;

    &:hover,
    &:focus {
      color: $black;

      &::after {
        height: calc(100% + 4px);
        width: calc(100% + 22px);
      }
    }

    &::after {
      background-color: $main-orange;
      border-radius: 14px;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
      width: 100%;
      z-index: -1;
    }
  }
}
