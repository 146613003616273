.section-offset {
  background-color: $section-offset;
}

.section-main {
  background-color: $section-main;

  &.border-top {
    border-top: 2px solid $nav-stroke;
  }
}

.section-white {
  background-color: $section-white;
}

main > div:has(.hero) + div section {
  padding-top: 30px;
}

.section-green,
.section-orange,
.section-yellow,
.section-pink,
.section-blue,
.section-offset,
.section-white,
.section-navy {
  padding: 20px 0;
}

.section-green,
.section-orange,
.section-yellow,
.section-pink,
.section-blue,
.section-navy {
  background-color: $section-white;

  > .container-fluid {
    border-radius: 50px;

    @include deviceOnly {
      border-radius: 25px;
    }
  }

  > div:not([class], [id]) > .container-fluid {
    border-radius: 50px;

    @include deviceOnly {
      border-radius: 25px;
    }
  }
}

.section-pink,
.section-blue,
.section-navy {
  color: $white;

  a,
  h1,
  h2,
  h3,
  p,
  button {
    color: $white !important;
  }
}

.section-green,
.section-orange,
.section-yellow {
  a:not(.btn) {
    color: $primary-dark;
  }
}

.section-green > .container-fluid,
.section-green > div:not([class], [id]) > .container-fluid {
  background-color: $section-green;
}

.section-orange > .container-fluid,
.section-orange > div:not([class], [id]) > .container-fluid {
  background-color: $section-orange;
}

.section-yellow > .container-fluid,
.section-yellow > div:not([class], [id]) > .container-fluid {
  background-color: $section-yellow;
}

.section-pink > .container-fluid,
.section-pink > div:not([class], [id]) > .container-fluid {
  background-color: $section-pink;
}

.section-blue > .container-fluid,
.section-blue > div:not([class], [id]) > .container-fluid {
  background-color: $section-blue;
}

.section-navy > .container-fluid,
.section-navy > div:not([class], [id]) > .container-fluid {
  background-color: $section-navy;
}

.section-gutter {
  &.featured {
    margin-top: 20px;
    padding-bottom: 0;

    @include desktop {
      margin-top: 40px;
    }
  }
}

.section-gutter-half {
  padding: 20px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.section-padded > .container-fluid,
.section-padded > div:not([class], [id]) > .container-fluid {
  padding: 40px;

  @include deviceOnly {
    padding: 20px;
  }
}

.search-section {
  padding: 70px 0;
}

.sub-section {
  padding: 8px 0;
}

.snippet-section {
  padding: 20px 10px;
}

.conversion-link-component {
  h2 {
    margin-top: 0;
  }

  .quick-cta {
    padding: 0;
  }
}
