.featured-cta-group {
  .wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    margin-top: 30px;
    position: relative;

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
  }
}

.featured-cta {
  @include fontSize(24px);

  --box-shadow: 0, 0%, 0%;

  align-items: center;
  background-color: $white;
  border-radius: 1rem;
  display: inline-flex;
  font-family: $museo-700;
  gap: 1rem;
  justify-content: center;
  line-height: 32px;
  padding: 1.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  top: 0;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 300ms;
  width: 100%;

  &:hover,
  &:focus {
    box-shadow: 0 6px 15px 0 hsl(var(--box-shadow) / 0.7);
    cursor: pointer;
    text-decoration: none;
    top: -4px;
  }

  @media (prefers-reduced-motion) {
    &:hover,
    &:focus {
      top: 0;
    }
  }

  &:focus {
    outline: 2px solid black;
  }

  &.navy {
    --box-shadow: var(--main);

    background-color: $main;
  }

  &.yellow {
    --box-shadow: var(--yellow);

    background-color: $main-yellow;
  }

  &.pink {
    --box-shadow: var(--pink);

    background-color: $main-pink;
  }

  &.orange {
    --box-shadow: var(--orange);

    background-color: $main-orange;
  }

  &.green {
    --box-shadow: var(--green);

    background-color: $main-green;
  }

  &.blue {
    --box-shadow: var(--blue);

    background-color: $main-blue;
  }

  &.offset {
    background-color: $section-offset;
  }

  &.navy,
  &.pink,
  &.blue {
    color: $white !important;
  }

  &.white,
  &.offset,
  &.green,
  &.orange,
  &.yellow {
    color: $primary-dark !important;
  }

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }
}
