.section-alerts {
  --arrow-right: '';
  --arrow-left: '';

  margin: -10px 0 10px;

  @include desktop {
    margin: -20px 0 20px;
  }

  .slick-next,
  .slick-prev {
    height: 28px;
    z-index: 2;
  }

  .slick-next {
    right: 10px;

    &::before {
      content: var(--arrow-right);
    }
  }

  .slick-prev {
    left: 10px;

    &::before {
      content: var(--arrow-left);
    }
  }

  .alert {
    --text-color: #{$black};
    --background-color: #{$secondary};

    background-color: var(--background-color);
    color: var(--text-color);
    display: flex;
    font-family: $sans-bold;
    height: 120px;
    padding: 20px 0;
    width: 100%;

    @include deviceOnly {
      .container-fluid {
        display: flex;
      }
    }

    @include desktop {
      align-items: center;
      height: 80px;
    }

    a,
    button {
      background-color: var(--background-color) !important;
      color: var(--text-color) !important;
    }

    p {
      margin: 0;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include desktop {
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
      }

      &.multiple {
        padding: 0 30px;

        @media screen and (min-width: 1380px) {
          padding: 0;
        }
      }

      &.dismissible {
        justify-content: space-between;
      }

      .icon {
        display: none;
        height: 40px;

        @include desktop {
          display: block;
        }
      }

      button {
        margin: 0 auto 0 0;
        width: inherit;

        &:hover {
          text-decoration: underline;
        }

        @include desktop {
          margin: 0 0 0 auto;
        }
      }
    }

    .message {
      @include desktop {
        margin: 0 40px 0 10px;
      }

      a {
        font-size: 16px;
        text-decoration: underline;
        text-transform: inherit;

        @include tabletAndDesktop {
          display: none;
        }
      }

      .count {
        font-family: $sans-bold;
      }

      .effective-date {
        @include mobile {
          display: none !important;
        }
      }

      @include tabletAndDesktop {
        .message-text a {
          display: inline;
        }
      }
    }
  }
}
