.business-units {
  padding-bottom: 20px;

  @include desktop {
    @include make-row;

    button,
    a.business-unit {
      @include fontSize(18px);

      background-color: $white;
      border-radius: 0;
      color: $gray-dark;
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 10px;
      padding: 24px 5px;
      text-align: center;
      text-decoration: none;

      &:focus {
        color: $gray-dark;
      }

      &:hover,
      &.active {
        background-color: $primary;
        color: $white;
      }
    }

    .business-unit-container {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 10px;
      position: relative;

      button,
      a.business-unit {
        margin: 0;
        width: 100%;
      }
    }
  }
}
