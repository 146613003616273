.testimonial-component {
  .panel {
    height: 100%;
    min-height: 442px;
    text-align: left;

    .testimonial {
      border-radius: 25px;
      height: 100%;
      padding: 0 30px;

      @include tablet {
        padding: 40px 60px;
      }

      @include desktop {
        border-radius: 50px;
        padding: 40px 100px;
      }

      h2,
      h3 {
        font-family: $sans-medium;
        font-weight: 300;
        margin: 20px 0;
      }

      h2 {
        @include fontSize(30px);

        margin-top: 10px;
      }

      h3 {
        @include fontSize(16px);

        margin-bottom: 30px;
      }

      &.green {
        background-color: $main-green;
      }

      &.pink {
        background-color: $main-pink;
      }

      &.blue {
        background-color: $main-blue;
      }

      &.orange {
        background-color: $main-orange;
      }

      &.yellow {
        background-color: $main-yellow;
      }

      &.offset {
        background-color: $offset;
      }

      &.green,
      &.orange,
      &.yellow {
        h2,
        h3 {
          color: $black;
        }
      }

      &.pink,
      &.blue {
        h2,
        h3 {
          color: $white;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }

    .icon-start {
      height: 40px;
      left: 0;
      position: absolute;
      top: 0;

      @include tablet {
        top: -10px;
      }

      @include mobile {
        top: -55px;
      }
    }

    .icon-end {
      bottom: 45px;
      height: 40px;
      position: absolute;
      right: 0;
      transform: rotate(180deg);

      @include mobile {
        bottom: -15px;
      }
    }

    .testimonial-body {
      align-self: center;
      display: inline-block;
      flex-grow: 1;
      padding: 30px 20px;
      position: relative;
      vertical-align: middle;

      @include tabletAndDesktop {
        margin: 0 30px;
        padding-left: 0;
        padding-right: 0;
        width: 50%;
      }
    }

    .testimonial-content {
      @include mobile {
        margin: 30px 0;
        position: relative;

        h2 {
          font-size: 24px;
          line-height: 30px;
        }

        &.margin {
          margin-top: 70px;
        }
      }
    }

    .testimonial-image {
      --background-image: '';

      background-image: var(--background-image);
      background-position: center;
      background-size: cover;
      border-radius: 25px;
      display: block;
      padding-top: 95.40222%;

      @include deviceOnly {
        // stylelint-disable-next-line
        aspect-ratio: 1 / 1;
      }

      @include tabletAndDesktop {
        min-height: 395px;
        padding-top: 0;
        width: 50%;
      }

      @include desktop {
        border-radius: 50px;
        max-width: 395px;
      }
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;
    z-index: 1;

    &::before {
      display: none;
    }

    &.image {
      margin-top: 100px;
    }

    &.slick-next {
      right: 10px;

      @include desktop {
        right: 30px;
      }
    }

    &.slick-prev {
      left: 10px;

      @include desktop {
        left: 30px;
      }
    }
  }

  .slick-list {
    height: 100%;
    margin: 0 -20px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    align-items: center;
    display: flex;
    height: inherit;
    justify-content: center;
    margin: 0 10px;

    > div {
      height: 100%;
      width: 100%;
    }
  }
}

section:has(.testimonial-component) {
  overflow-x: hidden;
}
