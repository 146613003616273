.material-landing {
  .search-area {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 30px 1fr;
    padding-top: 20px;

    @include deviceOnly {
      gap: 16px;
      grid-template-columns: 1fr;
      padding: 0;
    }

    .or {
      @include fontSize(20px);

      display: grid;
      font-weight: 600;
      place-items: center;
      text-transform: uppercase;
    }
  }
}
