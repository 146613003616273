.clear {
  @include clearfix();
}

.hidden {
  display: none;
}

[v-cloak] {
  display: none;
}

.hide-mobile {
  display: none !important;
}

.visible {
  display: block;
}

.collapse-shown {
  overflow: inherit;
}

.collapse-hide {
  overflow: hidden;

  @include desktop {
    overflow: visible;
  }
}

.content-overlay {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.fixed {
  position: fixed;

  &.full-width {
    bottom: 0;
    width: 100%;
    z-index: 99999;
  }
}

.collapse {
  display: none;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.no-padding {
  padding: 0;
}

// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.show-on-focus {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute !important;
  width: 1px;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static !important;
    width: auto;
  }
}

.br-xs {
  display: none;

  @include mobile {
    display: block;
  }

  &.full {
    height: 100%;
    line-height: 0;
    width: 100%;
  }
}

.br-sm {
  display: none;

  @include deviceOnly {
    display: block;
  }
}

.br-md {
  display: none;

  @include tabletAndDesktop {
    display: block;
  }

  &.full {
    height: 100%;
    line-height: 0;
    width: 100%;
  }
}

.br-md-lg {
  display: none;

  @include tablet {
    display: block;
  }
}

.br-lg {
  display: none;

  @include desktop {
    display: block;
  }
}

.br-md-flx {
  display: none;

  @include tabletAndDesktop {
    display: flex;
  }
}

.br-lg-flx {
  display: none;

  @include desktop {
    display: flex;
  }
}

.br-xl-inline {
  display: none;

  @media screen and (min-width: 1190px) {
    display: inline;
  }
}

.br-sm-inline {
  display: none;

  @media screen and (max-width: 1189px) {
    display: inline;
  }
}
