.multiple-item-component {
  .item-container {
    flex-wrap: wrap;
    margin: 20px 0 0;

    @include tabletAndDesktop {
      display: flex;
      gap: 40px;
    }

    .item {
      flex: 1 1 50%;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include tabletAndDesktop {
        flex: 1 1 25%;
        margin-bottom: 0;
      }

      .inner {
        img {
          border-radius: 25px;
          width: 100%;

          @include desktop {
            border-radius: 50px;
          }
        }

        .text {
          margin: 20px 0 0;
        }
      }
    }

    &.thirds {
      .item {
        flex: 0 0 calc(33% - 30px);
      }
    }
  }
}
