.four-bu-component {
  position: relative;
}

.four-bu-component,
.mobile-modal {
  .newsroom-link {
    display: block;
    text-transform: uppercase;
  }
}
