.state-selector-container {
  justify-content: center;
  text-align: center;

  @include desktop {
    display: flex;
  }

  h3,
  p {
    margin: 0;
  }

  h3 {
    text-transform: uppercase;
  }

  p {
    @include fontSize(18px);

    margin: 10px 0 30px;
  }

  .state-selector {
    @include desktop {
      flex: 0 1 58.3333%;
    }

    // Overrides for State Select only
    .select-input {
      &::after {
        top: 25px;
      }

      select {
        @include fontSize(18px);

        height: 60px;
        padding: 10px 20px;
      }
    }
  }
}
