@font-face {
  font-family: 'MuseoSans-100';
  src: url('../fonts/MuseoSans-100.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-100Italic';
  src: url('../fonts/MuseoSans-100Italic.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-300';
  src: url('../fonts/MuseoSans-300.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-300Italic';
  src: url('../fonts/MuseoSans-300Italic.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-500';
  src: url('../fonts/MuseoSans-500.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-500Italic';
  src: url('../fonts/MuseoSans-500Italic.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-700';
  src: url('../fonts/MuseoSans-700.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-700Italic';
  src: url('../fonts/MuseoSans-700Italic.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-900';
  src: url('../fonts/MuseoSans-900.otf') format('opentype');
}

@font-face {
  font-family: 'MuseoSans-900Italic';
  src: url('../fonts/MuseoSans-900Italic.otf') format('opentype');
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-LightItalic';
  src: url('../fonts/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Medium';
  src: url('../fonts/OpenSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-MediumItalic';
  src: url('../fonts/OpenSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url('../fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  src: url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}
