.featured-card .container-fluid {
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  @include desktop {
    border-radius: 50px;
    padding: 40px;
  }

  img {
    border-radius: 16px;
    width: 100%;
    will-change: transform;

    @include desktop {
      border-radius: 24px;
    }
  }

  h2,
  .text {
    margin: 0;
  }

  h2 {
    @include fontSize(36px);
  }

  .text {
    @include fontSize(20px);

    margin-top: 12px;
  }

  .content {
    margin-top: 40px;
    will-change: transform;
  }

  .cta {
    display: flex;
    justify-content: left;
    margin-top: 40px;

    .btn-container {
      display: inline-block;
      position: relative;
      z-index: 1;

      .btn {
        line-height: 2.7;

        &:hover {
          background-image: linear-gradient(#0003 0 0);
          text-decoration: none;
        }

        &.green {
          background-color: $main-green;
          color: $black;
        }

        &.pink {
          background-color: $main-pink;
          color: $white;
        }

        &.blue {
          background-color: $main-blue;
          color: $white;
        }

        &.orange {
          background-color: $main-orange;
          color: $black !important;
        }

        &.yellow {
          background-color: $main-yellow;
          color: $black !important;
        }

        &.white {
          background-color: $white;
          color: $black !important;
        }

        &.offset {
          background-color: $offset;
          color: $black !important;
        }

        &.navy {
          background-color: $main;
          color: $white;
        }
      }
    }
  }
}
