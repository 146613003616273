.material-listing {
  align-items: flex-start;
  display: flex;
  gap: 24px;

  .image {
    flex-shrink: 0;
    max-width: 160px;
    object-fit: contain;
    width: 160px;
  }

  .content {
    align-content: start;
    display: grid;
    gap: 2px;

    a,
    h3 {
      color: #026dff;

      &:is(.client-layout *) {
        color: var(--brand-color);
      }

      &:hover {
        color: #026dff;

        &:is(.client-layout *) {
          color: var(--brand-color);
        }
      }
    }

    h3,
    p,
    a {
      margin: 0;
      overflow-wrap: anywhere;
      text-decoration: none;
      text-wrap: pretty;
    }

    .italics {
      font-style: italic;
    }
  }
}
