.breadcrumb {
  font-family: $sans-semibold;
  margin: 0;
  padding-top: 20px;

  a {
    font-family: $sans-semibold;
  }

  img {
    max-height: 15px;
    padding-right: 5px;
    vertical-align: middle;
  }

  .mobile-breadcrumb {
    text-decoration: underline;
    vertical-align: middle;
  }
}
