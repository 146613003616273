.quick-cta {
  --background-color: #{$white};
  --text-color: #{$black};
  --cta-background-color: #{$main};
  --cta-text-color: #{$white};
  --cta-main-green: #{$main-green};
  --cta-main-pink: #{$main-pink};
  --cta-main-blue: #{$main-blue};
  --cta-main-orange: #{$main-orange};
  --cta-main-yellow: #{$main-yellow};
  --cta-main-white: #{$white};
  --cta-main-offset: #{$offset};
  --cta-main-navy: #{$main};
  --cta-text-black: #{$black};
  --cta-text-white: #{$white};

  padding: 20px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--text-color) !important;
  }

  .btn-container .btn {
    background-color: var(--cta-background-color) !important;
    color: var(--cta-text-color) !important;

    &:hover {
      background-image: linear-gradient(#0003 0 0);
    }
  }

  .container-fluid {
    border-radius: 25px;

    @include desktop {
      border-radius: 50px;
      display: flex;

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }

  .container-fluid:not(.conversion) {
    background-color: var(--background-color);
  }

  .inner {
    padding: 60px 15px;

    @include desktop {
      align-items: center;
      display: flex;
      flex: 0 0 58.33333%;
      justify-content: space-between;
      padding: 20px 30px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
    }

    .content {
      color: var(--text-color);
      flex: 0 0 58.33333%;
      text-align: center;

      @include desktop {
        text-align: inherit;
      }
    }

    .cta {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      @include desktop {
        flex: 0 0 41.66667%;
        justify-content: flex-end;
        margin-top: 0;
      }
    }

    .btn-container {
      display: inline-block;
      position: relative;
      z-index: 1;

      a,
      button {
        background-color: transparent;
        color: var(--cta-text-color) !important;
        display: inline-block;
        height: 100%;
        width: 100%;
        z-index: 0;

        &::before,
        &::after {
          border-radius: 25px;
          content: '';
          display: block;
          height: inherit;
          left: 0;
          position: absolute;
          top: 0;
          width: inherit;
        }

        &::after {
          background: #0003;
          display: none;
          z-index: -50;
        }

        &::before {
          z-index: -100;
        }

        &:hover {
          text-decoration: none;

          &::after {
            display: block;
          }
        }
      }
    }
  }
}

.conversion-link-component .quick-cta {
  margin-top: 15px;
}
