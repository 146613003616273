/* stylelint-disable selector-class-pattern */
.sortable-list {
  @mixin grabber {
    background-image: url($grip-vertical-solid-svg);
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    font-size: 1.5rem;
    height: 100%;
    left: 0.5rem;
    margin-right: 1rem;
    opacity: 0.8;
    position: absolute;
    width: 100%;
  }

  border: 1px solid $black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 12px;
  transition: box-shadow .2s ease;

  &:focus-visible {
    border-color: $main-blue;
    box-shadow: 0 0 0 2px hsl(var(--blue)) !important;
    outline: none;
  }

  &-item {
    background-color: white;
    border: 1px solid $gray;
    border-radius: 0.5rem;
    padding: 0.75rem 2rem;
    position: relative;
    width: 100%;

    &.draggable-mirror {
      animation: shadow 0.2s ease-in-out forwards;

      @keyframes shadow {
        0% {
          box-shadow: none;
        }

        100% {
          box-shadow: rgb(149 157 165 / 0.3) 0 8px 24px;
        }
      }

      &::before {
        @include grabber;
      }
    }

    &:hover {
      cursor: grab;

      &::before {
        @include grabber;
      }
    }

    &:focus-visible::before {
      @include grabber;

      background-image: url($arrows-vertical-solid-svg);
      height: 40%;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

    &.draggable-container--is-dragging,
    .draggable-source--is-dragging {
      cursor: grabbing;
    }

    .draggable-source--is-dragging {
      visibility: hidden;
    }
}
