/*!
 * Bootstrap Grid v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import '../../utils/_variables.scss';
@import 'functions';
@import 'variables';

//
// Grid mixins
//

@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';
@import 'grid';
@import 'utilities/flex';
