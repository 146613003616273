.material-library {
  .container-fluid {
    background-color: inherit;
  }

  .search-input {
    position: relative;

    .placeholder {
      @include fontSize(24px);

      left: 34px;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s;

      @include deviceOnly {
        @include fontSize(20px);
      }

      &.moved {
        left: 0;
        scale: 0.8;
        top: -24px;

        @include deviceOnly {
          display: none;
        }
      }
    }

    input[type="text"] {
      padding: 12px 136px 12px 32px;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding: 12px 32px;
    }

    input[type="text"],
    select {
      @include fontSize(24px);

      border: 2px solid $gray;
      border-radius: 40px;
      height: 64px;
      line-height: 32px;
      outline: 2px solid $white;
      transition: 0.3s ease-in-out;
      width: 100%;

      &:focus,
      &:focus-visible {
        box-shadow: 0 0 0 2px $main-blue;
        outline: 2px solid $main-blue;

        &:is(.client-layout *) {
          box-shadow: 0 0 0 2px var(--brand-color);
          outline: 2px solid var(--brand-color);
        }
      }

      @include deviceOnly {
        @include fontSize(20px);

        height: 56px;
      }
    }

    label {
      width: 100%;
    }

    label:has(select)::after {
      background-image: url($chevron-svg);
      background-repeat: no-repeat;
      background-size: 16px;
      content: '';
      display: block;
      height: 15px;
      pointer-events: none;
      position: absolute;
      right: 16px;
      top: calc(50% + 4px);
      transform: translateY(-50%);
      width: 18px;
      z-index: 9;
    }
  }

  .search-icon {
    filter: invert(1);
    height: 20px;
    outline-offset: 2px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;

    &:focus-visible {
      outline: auto;
    }
  }

  .clear-search {
    @include fontSize(18px);

    background: url($circle-x-svg) right 0 bottom 48% no-repeat;
    color: $main-blue;
    display: none;
    outline-offset: 2px;
    padding: 10px 20px;
    position: absolute;
    right: 60px;
    text-transform: none;
    top: 50%;
    transform: translateY(-50%);
    width: max-content;

    &.visible {
      display: block;
    }

    &:is(.client-layout *) {
      color: $black;
      filter: brightness(0);
      font-weight: 900;
    }

    &:hover {
      background-color: transparent;
    }

    &:focus-visible {
      background-position-x: 85%;
      color: $main-blue;
      outline: auto;
      padding-right: 35px;
    }
  }

  .pager .current-page {
    background-color: $main-blue;
    color: $white;

    &:is(.client-layout *) {
      background-color: var(--button-color);
      color: $black;
    }
  }
}
