// Button styles for links, spans, etc.
.btn,
button:where(:not(.no-style)),
input[type='button'],
input[type='reset'],
input[type='submit'] {
  @include btn();

  @include desktop {
    &:hover {
      background-color: $primary-offset;
    }
  }

  &:disabled {
    background-color: $gray-light;
    color: $gray;
  }

  &.btn-lowercase {
    text-transform: initial;
  }

  &.btn-secondary {
    background-color: $secondary;

    @include desktop {
      &:hover {
        background-color: $secondary-offset;
      }
    }
  }

  &.btn-primary-alt {
    background-color: $white;
    color: $primary;

    @include desktop {
      &:hover {
        background-color: $gray-lightest;
      }
    }
  }

  &.btn-filter {
    background: transparent;
    color: $primary;
    padding: 0;
    text-transform: inherit;
    transition: background 0.2s ease;

    &:focus {
      outline: 0;
    }

    &.active {
      color: $main;

      @include desktop {
        background-color: $main;
        border-color: transparent;
        color: $white;
      }
    }

    @include deviceOnly {
      margin-bottom: 10px;
    }

    @include desktop {
      background-color: $white;
      border: 1px solid $gray;
      margin: 0 10px;
      padding: 5px 20px;

      &:hover,
      &:focus {
        background-color: $main;
        border-color: transparent;
        color: $white;
      }
    }
  }

  &.anchor-link {
    background: transparent;
    border-radius: 0;
    color: $primary;
    display: inline-block;
    padding: 0;
    text-transform: inherit;
    transition: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 0;
    }
  }
}

.btn-no-style {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  text-align: left;
  width: 100%;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: transparent;
  }

  &.as-link {
    color: $primary;
    text-align: right;
    text-transform: inherit;
    width: auto;
  }
}
