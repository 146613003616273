// By default hide items that are only show on print
.show-on-print {
  display: none !important;
}

// Fix print view
@media print {
  .container {
    width: 100%;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-10 {
    width: 83.33334%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-7 {
    width: 58.33336%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-3 {
    width: 25% !important;
  }

  .col-md-2 {
    width: 16.66664%;
  }

  .col-md-1 {
    width: 8.33332%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-pull-10 {
    right: 83.33334%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-7 {
    right: 58.33336%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66664%;
  }

  .col-md-pull-1 {
    right: 8.33332%;
  }

  .col-md-pull-0 {
    right: 0;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-push-10 {
    left: 83.33334%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-7 {
    left: 58.33336%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-4 {
    left: 33.3333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66664%;
  }

  .col-md-push-1 {
    left: 8.33332%;
  }

  .col-md-push-0 {
    left: 0;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33334%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33336%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66664%;
  }

  .col-md-offset-1 {
    margin-left: 8.33332%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}

@media print {
  @page {
    margin: 0;
    size: auto;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
  }

  header,
  .c-header,
  footer,
  #s-user-alerts,
  #s-main-menu,
  #chat,
  .growl-animated,
  .m-btn,
  .hide-on-print {
    display: none !important;
  }

  .show-on-print {
    display: block !important;
  }
}
