.newsroom-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  @include deviceOnly {
    gap: 20px;
  }
}

.newsroom-featured .newsroom-list.padded {
  margin-top: 20px;
}

.newsroom-landing {
  .show-more {
    @include fontSize(18px);

    border-radius: 30px;
    display: block;
    margin: 20px auto 0;
    padding: 20px 50px;

    @include desktop {
      margin: 0 auto;
    }
  }

  // Featured card style overrides
  .featured {
    h2 {
      margin-top: 0;
    }

    .newsroom-list {
      .card {
        .content {
          .inner-content {
            .tag {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}
