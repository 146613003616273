* body:has(.white-label) {
  font-family: $museo-300 !important;

  #nav-mobile {
    position: relative;
  }

  .client-layout {
    @include deviceOnly {
      padding: 0 !important;
    }
  }
}

.mobile-divider {
  border-top: 8px solid var(--brand-color);
  margin-inline: -10px;
}

.white-label {
  --brand-color: '';

  @include desktop {
    border-top: 8px solid var(--brand-color);
  }

  [id^='react_'] section {
    padding-block: 0 32px;
  }

  &-listing {
    padding-block: 32px;

    @include desktop {
      padding-bottom: 80px;
      padding-top: 60px;
    }

    .container-fluid {
      padding-inline: 0;
    }
  }

  &-category-selector {
    align-items: center;
    display: flex;
    gap: 6px;

    p {
      font-family: $museo-700, sans-serif !important;
      font-weight: 600;
      margin: 0;
    }

    &-select {
      align-items: center;
      display: flex;
      max-width: 200px;
      position: relative;
      width: 100%;

      select {
        appearance: none;
        border: 1px solid #a0aec0;
        border-radius: 20px;
        display: flex;
        flex-shrink: 1;
        font-family: $museo-300, sans-serif !important;
        gap: 12px;
        padding: 6px 40px 6px 14px;
      }

      img {
        filter: brightness(0%);
        height: 20px;
        position: relative;
        right: 30px;
        top: 1px;
        transform-origin: 50% 45%;
        transition: transform 0.2s ease-in-out;
        width: 20px;

        &.expanded {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &-categories {
    display: grid;
    gap: 32px;
  }

  &-category {
    &-title {
      align-items: center;
      display: flex;
      font-family: $museo-700 !important;
      font-size: 18px;
      gap: 6px;
      margin-block: 32px;
      text-wrap: nowrap;

      &::after {
        background-color: var(--brand-color);
        content: '';
        display: block;
        height: 1px;
        margin-block: 1rem;
        width: 100%;
      }
    }

    &-materials {
      display: grid;
      gap: 32px 30px;
      grid-template-columns: 1fr;
      place-content: center;

      @include tablet {
        grid-template-columns: 1fr 1fr;
      }

      @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
