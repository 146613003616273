.featured-image {
  img {
    border-radius: 25px;
    margin: 0;

    @include desktop {
      border-radius: 50px;
    }

    &.margin {
      margin: 10px 0;
    }
  }

  .content {
    @include desktop {
      display: flex;

      > div {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      }
    }

    img {
      padding: 10px 0;

      @include desktop {
        padding: 20px 0;
      }
    }
  }
}
