@mixin btn() {
  @include transition(0.2s ease);

  background-color: $main;
  border: 0;
  border-radius: 25px;
  box-shadow: none;
  color: $white;
  cursor: pointer;
  font-family: $sans-bold;
  -webkit-font-smoothing: antialiased;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:active {
    outline: none;
  }

  &:focus,
  &:active {
    color: $white;
    text-decoration: none;
  }
}
