.circle {
  bottom: -25px;
  display: block;
  flex: 0 0 100%;
  margin: 0 auto;
  opacity: 0;
  position: relative;
  z-index: 2;

  &.active {
    opacity: 1;
  }

  &.large {
    &::after {
      border-width: 26px;
      margin-left: -26px;
    }
  }

  &::after,
  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    margin-left: -15px;
    position: absolute;
    width: 0;
  }

  &::after {
    border-color: $nav-offset;
    border-radius: 50%;
    border-width: 15px;
    top: -12px;
  }

  &::before {
    border-color: $nav-stroke;
    border-radius: 50%;
    border-width: 15px;
    top: -15px;
  }
}

.circle-no-border {
  z-index: 1;

  &.active {
    bottom: -26px;

    &::after {
      opacity: 1;
    }
  }

  &::after {
    border-color: $white;
    border-radius: 50%;
    content: '';
    opacity: 0;
  }

  &::before {
    display: none;
  }
}
