// Breakpoints
$breakpoint-small: 320px;
$breakpoint-medium: 768px;
$breakpoint-large: 1025px;
$breakpoint-extra-large: 1440px;

:root {
  --main: 240deg 90% 8%;
  --yellow: 56deg 100% 50%;
  --pink: 332deg 76% 50%;
  --orange: 41deg 100% 50%;
  --green: 154deg 100% 40%;
  --blue: 215deg 100% 50%;
}

// Primary
$main: hsl(var(--main));
$main-yellow: hsl(var(--yellow));
$main-pink: hsl(var(--pink));
$main-orange: hsl(var(--orange));
$main-green: hsl(var(--green));
$main-blue: hsl(var(--blue));

// Secondary
$primary: $main-green;
$primary-dark: $main;
$primary-offset: $main;
$secondary: $main-pink;
$secondary-offset: hsl(332deg 92% 42%);
$tertiary: $main-orange;

// Tertiary
$white: hsl(0deg 0% 100%);
$gray-lightest: hsl(200deg 25% 95%);
$gray-light: hsl(0deg 0% 94%);
$gray: hsl(202deg 6% 66%);
$gray-dark: hsl(0deg 0% 16%);
$black-offset: hsl(255deg 7% 11%);
$black: hsl(0deg 0% 0%);
$offset: hsl(240deg 8% 95%);
$nav-offset: hsl(240deg 8% 90%);

// Text
$text-body: $gray-dark;
$text-heading: $main;
$text-link: $main-blue;
$text-link-hover: $main;

// Background
$highlight: hsl(58deg 100% 50%);
$bg-body: $gray;

// Section
$section-offset: $offset;
$section-main: $main;
$section-white: $white;
$section-green: $main-green;
$section-pink: $main-pink;
$section-blue: $main-blue;
$section-orange: $main-orange;
$section-yellow: $main-yellow;
$section-navy: $main;

// Navigation
$nav-stroke: hsl(0deg 0% 82%);

// Forms
$form-error: hsl(0deg 95% 41%);
$form-success: hsl(103deg 80% 33%);
$input-stroke: $black;
$input-watermark: $black-offset;
$input-stroke-disabled: $gray-dark;

// Fonts
/* stylelint-disable */
$sans-light: 'OpenSans-Light', Sans-Serif;
$sans-light-italic: 'OpenSans-LightItalic', Sans-Serif;

$sans-medium: 'OpenSans-Medium', Sans-Serif;
$sans-medium-italic: 'OpenSans-MediumItalic', Sans-Serif;

$sans-semibold: 'OpenSans-SemiBold', Sans-Serif;
$sans-semibold-italic: 'OpenSans-SemiBoldItalic', Sans-Serif;

$sans-bold: 'OpenSans-Bold', Sans-Serif;
$sans-bold-italic: 'OpenSans-BoldItalic', Sans-Serif;

$sans-extra-bold: 'OpenSans-ExtraBold', Sans-Serif;
$sans-extra-bold-italic: 'OpenSans-ExtraBoldItalic', Sans-Serif;

$museo-100: 'MuseoSans-100', Sans-Serif;
$museo-100-italic: 'MuseoSans-100Italic', Sans-Serif;

$museo-300: 'MuseoSans-300', Sans-Serif;
$museo-300-italic: 'MuseoSans-300Italic', Sans-Serif;

$museo-500: 'MuseoSans-500', Sans-Serif;
$museo-500-italic: 'MuseoSans-500Italic', Sans-Serif;

$museo-700: 'MuseoSans-700', Sans-Serif;
$museo-700-italic: 'MuseoSans-700Italic', Sans-Serif;

$museo-900: 'MuseoSans-900', Sans-Serif;
$museo-900-italic: 'MuseoSans-900Italic', Sans-Serif;
/* stylelint-enable */

// SVGs
$chevron-svg: '../images/icons/layout/Arrow-Down-Gray.svg';
$chevron-white-svg: '../images/icons/layout/Arrow-Down.svg';
$circle-x-svg: '../images/icons/layout/Circle-X.svg';
$calendar-svg: '../images/icons/layout/Calendar.svg';
$grip-vertical-solid-svg: '../images/icons/layout/grip-vertical-solid.svg';
$arrows-vertical-solid-svg: '../images/icons/layout/arrows-vertical-solid.svg';

// Third-party
$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
