.featured-video {
  .item {
    align-items: center;
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px;
    margin: 20px 10px;
    padding-top: 43.38%;
    position: relative;

    @include desktop {
      border-radius: 50px;
    }

    .overlay {
      background-image: linear-gradient(#0000 0%, #0000 20%, $black 120%);
      border-radius: 25px;
      bottom: 0;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      z-index: 0;

      @include desktop {
        border-radius: 50px;
      }
    }
  }

  &.single-video-featured {
    .section-intro {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    .item {
      margin-left: auto;
      margin-right: auto;
      max-width: 70%;
      padding-top: 0 !important;
      position: relative;

      @include mobile {
        max-width: 100%;
      }

      &::before {
        content: '';
        display: block;
        padding-bottom: calc(100% / (16 / 9));
      }

      > :first-child {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .global-props {
    @include desktop {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  }

  .section-gutter-half {
    padding: 0;
  }

  .video-content {
    bottom: 10px;
    left: 15px;
    position: absolute;

    .video-sub-title {
      text-transform: none;
    }
  }
}

@include desktop {
  .featured-video {
    .item {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      margin: 0 10px;
    }

    .section-intro {
      &.padded {
        padding-top: 20px;
      }

      .grid {
        flex: 0 1 58.33333%;
        max-width: 58.33333%;
      }
    }

    .section-gutter-half {
      padding: 20 0;
    }

    .sub-flex-box {
      display: flex;
      flex: 0 1 41.66667%;
      flex-wrap: wrap;
      margin-right: 10px;
      max-width: 41.66667%;

      .grid {
        flex: 0 0 100%;
        margin: 5px 0;
        max-width: 100%;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .video-content {
      bottom: 40px;
      left: 50px;
      position: absolute;
    }
  }
}
