.pager {
  border: 1px solid $gray-light;
  border-radius: 6px;
  grid-column: span 2;
  overflow: hidden;
  width: max-content;

  @include deviceOnly {
    grid-column: span 1;
    justify-self: center;
  }

  span {
    color: $black;
    display: inline-block;
    text-align: center;
    width: 32px;

    @media screen and (max-width: 380px) {
      padding-inline: 0;
      width: 20px;
    }
  }

  button {
    border-left: 1px solid $gray-light;
    color: $black;
    padding: 6px;
    text-align: center;
    width: 32px;

    @media screen and (max-width: 380px) {
      padding-inline: 0;
      width: 28px;
    }

    &:first-of-type {
      border-left: 0;
      margin-left: 0;
    }

    &.current-page {
      background-color: $main-green;
    }

    &:has(+ span) {
      border-right: 1px solid $gray-light;
    }
  }

  .pager-prev,
  .pager-next {
    img {
      height: 16px;
      position: relative;
      top: 2px;
      width: 16px;
    }

    @media screen and (max-width: 380px) {
      padding-inline: 0;
      width: 28px;
    }
  }
}
