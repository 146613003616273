.ccpa-section {
  bottom: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  &.closed {
    transform: translateY(100%);
  }
}

.ccpa-banner {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;

  @include desktop {
    flex-direction: row;
    justify-content: flex-start;
  }

  a {
    color: $secondary;
  }

  p {
    margin: 0 0 10px;
    text-align: center;

    @include desktop {
      margin: 0 20px 0 0;
      text-align: left;
    }
  }

  .btn {
    color: $main;
  }
}
