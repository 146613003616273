.material-item-component {
  .item-container {
    flex-wrap: wrap;
    margin: 20px -15px 0;

    @include tabletAndDesktop {
      display: flex;
    }

    .item {
      flex: 1 1 50%;

      @include desktop {
        flex: 1 1 25%;
      }

      .inner {
        margin: 11px 15px 15px;

        img {
          width: auto;
        }

        .text {
          margin: 20px 0;
        }

        .desc {
          margin-top: -1.3rem;
          padding-left: 16px;
        }
      }
    }

    &.thirds {
      .item {
        flex: 0 0 33.3333%;
      }
    }
  }
}
