.table-row {
  display: flex;
  flex-wrap: wrap;

  .item {
    flex: 0 1 100%;
  }

  @include deviceOnly {
    .item {
      &.last-item {
        margin-top: 10px;
        order: 1;
      }
    }
  }

  @include desktop {
    .item {
      flex: 1 1 29%;

      &-inner {
        display: block;
        padding: 0 5px;
      }

      &.half {
        flex: 0 1 10%;
      }

      &.full {
        flex: 0 1 100%;
        padding: 0 5px;
      }
    }
  }

  &.content {
    border-top: 1px solid $gray-light;
    margin-top: 15px;
    padding: 20px 0;
  }

  &.header {
    display: none;
    font-family: $sans-bold;

    @include desktop {
      display: flex;

      .item {
        flex: 0 1 30%;
        padding-left: 5px;
      }
    }
  }
}
