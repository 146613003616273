.hero-large {
  --background-color: transparent;
  --text-color: #{$white};
  --slide-justify-content: flex-start;
  --slide-text-align: left;
  --button-justify-content: flex-start;
  --hero-main-green: #{$main-green};
  --hero-main-pink: #{$main-pink};
  --hero-main-blue: #{$main-blue};
  --hero-main-orange: #{$main-orange};
  --hero-main-yellow: #{$main-yellow};
  --hero-main-white: #{$white};
  --hero-main-offset: #{$offset};
  --hero-main-navy: #{$main};
  --hero-text-black: #{$black};
  --hero-text-white: #{$white};

  .color-wrapper,
  .video-wrapper,
  .image-wrapper {
    color: var(--text-color);
  }

  .color-wrapper {
    background-color: var(--background-color);
  }

  .button-wrapper {
    justify-content: var(--button-justify-content);
  }

  .slide-content {
    color: inherit;
    justify-content: var(--slide-justify-content);
    text-align: var(--slide-text-align);
  }

  img,
  .item {
    max-height: 46vh;
    min-height: 15.5rem;

    &:is(.client-layout *) {
      max-height: calc(46vh + 105px);
    }

    @include deviceOnly {
      max-height: 44vh;

      &:is(.client-layout *) {
        max-height: calc(44vh + 105px);
      }
    }

    @include tablet {
      min-height: 11.5rem;
    }
  }

  img {
    object-fit: cover;
    object-position: 50% 80%;
    width: 100%;

    @include deviceOnly {
      object-position: 50% 15%;
    }

    @include tablet {
      object-position: 50% 50%;
    }
  }

  .item {
    position: relative;
  }

  .content {
    align-items: center;
    bottom: 10%;
    display: flex;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 101;

    &-overlay {
      background-image: linear-gradient(#0000 0%, #0000 20%, $black 120%);
      bottom: 0;
      display: none;
      height: 100%;
      position: absolute;
      z-index: 100;

      @include mobile {
        display: block;
      }
    }

    @include tabletAndDesktop {
      bottom: 0;
      padding-bottom: 0;
      top: 60%;
      transform: translateY(-50%);
    }

    @include desktop {
      top: 50%;
    }
  }

  .inner-content {
    @include desktop {
      padding-right: 75px;
      width: 55%;
    }

    @include desktopLarge {
      padding-right: 0;
      width: 50%;
    }
  }
}
