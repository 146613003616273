.gh-background {
  background: #f2f2f4 0% 0% no-repeat padding-box;
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
  opacity: 1;
  padding: 30px;
  padding-left: 95px;
  width: 100%;
}

.link-color {
  color: #507fae !important;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  text-decoration: none;
}

.slash-color {
  color: #bbb;
}

.gh-background div:last-child > span {
  display: none;
}

.gh-background a:hover {
  color: #292929 !important;
  text-decoration: underline;
}

@media screen and (max-width: 485px) {
  .slash-color {
    color: #bbb;
    display: none;
  }

  .gh-background a {
    margin-left: 0;
    margin-right: 0;
  }

  .gh-background {
    padding-left: 10px;
  }

  .mobile-device-cls {
    font-size: 18px;
    margin-bottom: 14px;
    padding: 0;
    text-align: left;
    width: 100%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1360px) {
  .gh-background {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .gh-background {
    padding-left: 70px;
  }
}

@media screen and (min-width: 1500px) {
  .gh-background {
    padding-left: 95px;
  }
}
