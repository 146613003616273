.accordion-list {
  margin: 100px -20px 0;

  .accordion {
    @include clear-list();

    span {
      display: block;
      padding: 20px;
    }

    li {
      @include transition(color 0.2s ease);

      border-bottom: 1px solid $nav-stroke;
      position: relative;

      p {
        color: $primary;
        display: block;
        margin: 0;
        padding: 15px 20px;

        @include fontSize(18px);
      }

      span {
        @include fontSize(18px);
        @include transition(0.2s ease);

        color: $primary;
        cursor: pointer;
        font-family: $sans-bold;
        text-decoration: none;
        text-transform: uppercase;
      }

      &.active {
        color: $main;

        .btn-contain {
          color: $main;

          a,
          span,
          p.active {
            color: $main;
          }
        }
      }

      img.chevron {
        position: absolute;
        right: 20px;
        top: 26px;
        width: 15px;
      }

      .expansion {
        border: none;
        padding-left: 20px;

        .inner-content {
          @include fontSize(16px);

          color: $text-body;
          padding: 0 20px 20px;

          a {
            display: inline-block;
          }
        }
      }
    }
  }
}
