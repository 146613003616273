.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  h2 {
    flex: 0 1 100%;
    font-family: $sans-light;
    margin-top: 0;
    text-align: center;
  }

  .input-wrapper {
    flex: 0 1 100%;
    position: relative;

    @include desktop {
      flex: 0 1 50%;
    }

    button {
      align-items: center;
      border-radius: 30px;
      display: flex;
      height: 60px;
      justify-content: center;
      padding: 0;
      position: absolute;
      right: -2px;
      top: 0;
      width: 60px;
      z-index: 3;

      img {
        height: 25px;
        width: 25px;
      }

      &.close-icon {
        background: transparent;
        right: 60px;

        &:focus {
          outline: none;
        }

        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    input {
      z-index: 2;
    }
  }

  .search-links {
    @include fontSize(20px);

    color: $primary;
    flex: 0 1 100%;
    font-family: $sans-bold;
    margin-top: 15px;
    text-align: center;
    text-decoration: none;

    &.light {
      color: $white;

      a {
        color: $white;
      }
    }
  }

  &-section-main {
    h2 {
      color: $white;
    }
  }
}

.search.offset {
  position: relative;

  .input-wrapper {
    top: -30px;

    @include desktop {
      flex: 0 1 66.66667%;
    }
  }

  .search-links {
    margin-top: -15px;
  }
}
