input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

input[type='file'] {
  background: $white;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $input-watermark;
  height: 32px;
  padding: 0;

  @include fontSize(14px);
}

textarea {
  max-width: 100%;
  min-height: 200px;
  min-width: 100%;
  resize: vertical;
  width: 100%;
}

label:has(+ select:not(.custom)) {
  position: relative;

  &::after {
    @include fontSize(18px);

    background-image: url($chevron-svg);
    background-repeat: no-repeat;
    background-size: 16px;
    bottom: -34px;
    content: '';
    display: block;
    height: 15px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    width: 18px;
    z-index: 999;
  }
}

select:not(.custom) {
  @include placeholder($input-watermark, $sans-medium);
  @include transition(box-shadow 0.2s ease);

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: $white;
  border: 1px solid $input-stroke;
  border-radius: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $black;
  cursor: pointer;
  font-family: $sans-medium;
  line-height: normal;
  outline: none;
  padding: 4px 9px;
  text-transform: none;
  width: 100%;

  &:not([multiple]) {
    height: 45px;
  }

  &::-ms-clear,
  &::-ms-expand {
    display: none;
  }

  &:disabled {
    background: $input-stroke-disabled;
    cursor: not-allowed;
    outline-color: $input-stroke-disabled;
  }

  &:focus-visible {
    border-color: $main-blue;
    box-shadow: 0 0 0 2px $main-blue;
  }

  option {
    color: $text-body;
    font-family: $sans-medium;
    line-height: 20px;
    text-transform: none;
  }
}

.radio-container {
  display: grid;
  grid-template-columns: 20px 1fr;

  & :first-child {
    display: flex;
    grid-column: 1 / 3;
    margin-bottom: 0;
  }

  input {
    top: 4px !important;
  }
}

input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea {
  -webkit-appearance: none;
}

input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'],
.form-control:not(input[type='checkbox'], input[type='radio']),
textarea {
  height: 45px;
  line-height: normal;
  outline: none;
  padding: 4px 9px;
  width: 100%;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  height: 16px;
  position: relative;
  top: 2px;
  width: 16px;
}

input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='checkbox'],
input[type='radio'],
.form-control:not(input[type='checkbox'], input[type='radio']),
textarea {
  @include placeholder($input-watermark, $sans-medium);
  @include transition(box-shadow 0.2s ease);

  background: $white;
  border: 1px solid $input-stroke;
  border-radius: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $text-body;
  line-height: normal;
  outline: none;

  &::-ms-clear {
    display: none;
  }

  &:disabled {
    background: $input-stroke-disabled;
    cursor: not-allowed;
    outline-color: $input-stroke-disabled;
  }

  &:focus-visible {
    border-color: $main-blue;
    box-shadow: 0 0 0 2px $main-blue !important;
    color: $text-body;
  }

  &.input-validation-error {
    border-color: $form-error;
    box-shadow: 0 0 0 2px $form-error !important;
  }

  &.search-input {
    @include fontSize(18px);
    @include placeholder($gray, $sans-medium-italic);

    border-color: $main-blue;
    border-radius: 30px;
    box-shadow: 0 0 3px 0 #00000080;
    height: 60px;
    padding: 10px 30px;
    position: relative;
    z-index: 2;
  }
}

.field-validation-error {
  @include fontSize(14px);

  color: $form-error;
  font-weight: bold;
}
