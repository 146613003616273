.triangle {
  bottom: -25px;
  display: block;
  flex: 0 0 100%;
  margin: 0 auto;
  opacity: 0;
  position: relative;
  transition: opacity 0.1s ease-out;
  z-index: 999;

  &.active {
    opacity: 1;
  }

  &.large {
    &::after {
      border-width: 30px;
      margin-left: -30px;
    }
  }

  &::after,
  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    margin-left: -15px;
    position: absolute;
    width: 0;
  }

  &::after {
    border-color: transparent transparent $nav-offset transparent;
    border-width: 15px;
    top: -12px;
  }

  &::before {
    border-color: transparent transparent $nav-stroke transparent;
    border-width: 15px;
    top: -15px;
  }
}

.triangle-no-border {
  z-index: 1;

  &.active {
    bottom: -11px;

    &::after {
      opacity: 1;
    }
  }

  &::after {
    border-color: $primary transparent transparent transparent;
    content: '';
    opacity: 0;
  }

  &::before {
    display: none;
  }
}
