.sitemap-landing {
  padding: 0 15px;

  .container-fluid {
    display: grid;
    gap: 25px;
    grid: auto-flow / repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    justify-items: flex-start;
    margin: 0 auto;

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      margin-block: 4px;
    }
  }
}
