.error-message {
  display: none;
}

.field-validation-error {
  color: red;
}

.form-error {
  .form-control {
    border-color: red;
  }
}

.validation-summary-errors {
  ul {
    @include clear-list();

    color: red;
    margin-bottom: 20px;
  }
}
