.close-button {
  background-color: #0000;
  padding: 0;
  position: absolute;
  right: 0;

  @include mobile {
    margin-right: 20px;
  }

  &:hover {
    background-color: #0000;
    color: $primary-offset;
  }
}

.modal-overlay {
  background: #000c;
  display: flex;
  height: auto;
  inset: 0;
  justify-content: center;
  padding: 0 10px;
  position: fixed;
  z-index: 20;

  .modal {
    align-self: center;
    max-width: 980px;
    outline: none;
    width: 100%;

    .container-fluid {
      align-items: center;
      display: block;
      flex-wrap: wrap;
      height: auto;
      padding: 0;
      position: relative;
      width: auto;

      .close-button {
        align-self: center;
        flex: 0 0 20px;
        height: 20px;
        margin: -30px 0 10px auto;
      }

      .iframe-container {
        max-width: 980px;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        width: 100%;

        iframe {
          border: 0;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.ReactModal__Body--open {
  overflow: hidden;

  &-desktop-scroll {
    @include deviceOnly {
      overflow: hidden;
    }
  }
}

.mobile-modal-overlay {
  height: auto;
  inset: 0;
  position: fixed;
  z-index: 20;

  .mobile-modal {
    background: $gray-light;
    height: auto;
    inset: 0;
    outline: none;
    overflow: auto;
    padding: 20px;
    position: absolute;
    z-index: 20;

    .content {
      &.active {
        margin-top: 40px;
      }
    }

    .btn-back {
      color: $primary;
      margin-top: 30px;

      img {
        height: 20px;
        margin-right: 7px;
        vertical-align: middle;
        width: auto;
      }

      p {
        @include fontSize(18px);

        display: inline-block;
        margin: 0;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }

    .tile-expansion {
      margin-top: 42px;
      position: relative;
      z-index: 10;
    }
  }
}
