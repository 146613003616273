.fade-in-container {
  transition:
    opacity 0.9s ease-out,
    transform 0.3s ease-out;
  will-change: opacity, visibility;

  &.visible {
    opacity: 1;
    transform: translate(0, 0) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
    visibility: visible;
  }

  &.invisible {
    opacity: 0;
    visibility: hidden;
  }

  &.translate-up {
    transform: translate(0, 5dvh) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
  }

  &.translate-down {
    transform: translate(0, -5dvh) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
  }

  &.translate-left {
    transform: translate(-5dvw, 0) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
  }

  &.translate-right {
    transform: translate(5dvw, 0) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
  }
}
