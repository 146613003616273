.stats-cycle {
  .container-fluid {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    text-align: center;
    width: 100%;
  }

  .stats-cycle-info {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    h2 {
      font-family: $museo-700;
      font-size: 3rem;
      line-height: 1;
      margin: 0;
    }

    p {
      font-family: $museo-300;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin: 0 auto;
      max-width: 48rem;
    }
  }

  .stats-cycle-statistics {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include desktop {
      flex-direction: row;
    }
  }

  &.section-green {
    background-color: $main-green;
  }

  &.section-pink {
    background-color: $main-pink;
  }

  &.section-blue {
    background-color: $main-blue;
  }

  &.section-orange {
    background-color: $main-orange;
  }

  &.section-yellow {
    background-color: $main-yellow;
  }

  &.section-navy {
    background-color: $main;
  }

  &.section-offset {
    background-color: $offset;
    padding: 40px;

    @include deviceOnly {
      padding: 20px;
    }
  }

  &.section-white {
    background-color: $white;
    padding: 40px;

    @include deviceOnly {
      padding: 20px;
    }
  }
}
