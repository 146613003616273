.section-network-availability {
  .section-gutter {
    .section-gutter {
      padding-bottom: 0;
    }
  }

  &:has(.has-divider) {
    .data-table {
      padding: 20px 0;
    }
  }
}
