.client-layout {
  header {
    .container-fluid {
      padding-left: 20px;
      padding-right: 20px;

      @include desktop {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .heading {
      align-items: center;
      display: flex;
      padding: 20px 0;
      text-align: left;

      &-no-icon {
        display: flex;
        flex-wrap: wrap;
      }

      h2 {
        @include fontSize(24px);

        flex-grow: 1;
        margin: 0;
      }

      img {
        height: 60px;
        margin-right: 40px;
      }
    }

    .links {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      padding: 45px 0 66px;
      text-align: left;

      a {
        @include fontSize(20px);

        flex: 0 0 25%;
        margin: 12px 0;
        text-transform: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
