.form-group {
  margin: 15px 0;
  position: relative;

  &.select-input {
    &::after {
      @include fontSize(18px);

      background-image: url($chevron-svg);
      background-repeat: no-repeat;
      background-size: 16px;
      bottom: 11px;
      content: '';
      display: block;
      height: 15px;
      pointer-events: none;
      position: absolute;
      right: 20px;
      width: 18px;
    }
  }

  .ktc-radio-list {
    display: grid;
  }
}

.form-wrapper {
  display: flex;

  .form {
    @include mobile {
      width: 100%;
    }

    @include tablet {
      flex: 0 0 60%;
      max-width: 60%;
    }

    @include desktop {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

.required-field-red-star {
  &::after {
    content: '*';
  }
}

form {
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: $primary-dark;
    border-radius: 10px;
  }

  .explanation-text {
    color: #8888;
    font-style: italic;
    margin: 0;
  }
}

.snippet-section {
  form {
    display: grid;

    @include mobile {
      width: 100%;
    }

    @include tablet {
      flex: 0 0 60%;
      max-width: 60%;
    }

    @include desktop {
      flex: 0 0 40%;
      max-width: 40%;
    }

    .explanation-text {
      margin-bottom: -5px;
    }

    br + label {
      margin-top: -5px;
    }

    input[type='radio'] + label {
      cursor: pointer;
      width: max-content;
    }

    label:has(+ input[type='checkbox']) {
      cursor: pointer;
      width: max-content;
    }

    input[type='checkbox'] + label:not(:has(+ input[type='checkbox'])) {
      cursor: pointer;
      margin-top: 2px;
    }

    input[type='submit'] {
      width: max-content;
    }
  }
}
