$primary-color: $primary;

// @import 'loaders.css/src/animations/ball-spin-fade-loader.scss';

@import 'loaders.css/src/variables';
@import 'loaders.css/src/mixins';
@import 'loaders.css/src/functions';

// @import '../variables';
// @import '../mixins';
// @import '../functions';

$radius: 25px;

@keyframes ball-spin-fade-loader {
  50% {
    opacity: 0.3;
    transform: scale(0.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@mixin ball-spin-fade-loader($n: 8, $start: 1) {
  @for $i from $start through $n {
    > div:nth-child(#{$i}) {
      $iter: calc(360 / $n);
      $quarter: calc(($radius / 2) + ($radius / 5.5));

      @if $i == 1 {
        left: 0;
        top: $radius;
      } @else if $i == 2 {
        left: $quarter;
        top: $quarter;
      } @else if $i == 3 {
        left: $radius;
        top: 0;
      } @else if $i == 4 {
        left: $quarter;
        top: -$quarter;
      } @else if $i == 5 {
        left: 0;
        top: -$radius;
      } @else if $i == 6 {
        left: -$quarter;
        top: -$quarter;
      } @else if $i == 7 {
        left: -$radius;
        top: 0;
      } @else if $i == 8 {
        left: -$quarter;
        top: $quarter;
      }

      animation: ball-spin-fade-loader 1s delay(0.12s, $n, $i - 1) infinite linear;
    }
  }
}

.ball-spin-fade-loader {
  @include ball-spin-fade-loader();

  left: -10px;
  position: relative;
  top: -10px;

  > div {
    @include balls();
    @include global-animation();

    position: absolute;
  }
}

.loader {
  align-items: center;
  background: #00000080;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
