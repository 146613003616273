.header-navigation {
  background: $main;
  isolation: isolate;
  position: sticky;
  top: 0;
  z-index: 5;

  .main-logo {
    --dot-left: 45px;
    --dot-top: 9px;
    --dot-width: 2px;

    border-radius: 9999px;
    display: flex;
    max-width: 150px;
    overflow: hidden;
    padding: 0.5rem;
    position: relative;
    z-index: 5;

    @media screen and (min-width: 1025px) {
      --dot-left: 41px;
      --dot-width: 3px;
    }

    @media screen and (min-width: 1150px) {
      --dot-left: 46px;

      max-width: 160px;
      padding: 0.5rem 0.75rem;
    }

    @include desktopLarge {
      --dot-left: 66px;
      --dot-top: 18px;
      --dot-width: 4px;

      max-width: 220px;
      padding: 1rem 14px 1rem 1.25rem;
    }

    &::before,
    &::after {
      aspect-ratio: 1;
      border-radius: 2px;
      content: '';
      left: var(--dot-left);
      position: absolute;
      top: var(--dot-top);
      transform: translate(-50%, -50%);
      transition:
        color 300ms,
        height 300ms,
        width 300ms;
      width: var(--dot-width);
    }

    &::before {
      background: $white;
      z-index: -2;
    }

    &::after {
      background: $main-orange;
      border-radius: 9999px;
      z-index: -1;
    }

    &:focus {
      outline: 2px $white solid;
    }

    &:hover,
    &:focus {
      &::before {
        transition-delay: 0ms;
        z-index: 1;
      }

      &::after {
        height: 400px;
        width: 400px;
      }
    }

    img {
      margin-right: -0.5rem;
      transition: all 300ms;
    }

    &:hover img,
    &:focus img {
      filter: invert(112%) sepia(4%) saturate(7456%) hue-rotate(202deg) brightness(89%)
        contrast(123%);
    }
  }

  .link-icon {
    aspect-ratio: 1;
    filter: brightness(0) invert(1);
    height: 14px;
    position: relative;
    width: 14px;
    z-index: 2;

    @include desktopLarge {
      height: 1rem;
      width: 1rem;
    }
  }

  .dropdown-caret {
    filter: brightness(0) invert(1);
    height: 14px;
    margin-bottom: 0.25rem;
    transition: transform 150ms ease-in-out;

    @include desktopLarge {
      height: 1rem;
    }
  }

  .navigation-button[data-is-open='true'] .dropdown-caret {
    transform: rotate(-180deg);
  }
}

.desktop-navigation {
  @include deviceOnly {
    display: none;
  }

  padding-inline: 10px;

  .navigation-list-wrapper {
    align-items: center;
    color: $white;
    display: flex;
    font-weight: bold;
    gap: 0.25rem;
    justify-content: space-between;
    margin-inline: auto;
    max-width: 1600px;
    padding-bottom: 17px;
    padding-top: 14px;

    @include desktop {
      gap: 0.5rem;
    }

    @include desktopLarge {
      gap: 1rem;
    }
  }

  .navigation-list {
    display: flex;
    gap: 0.25rem;
    list-style: none;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 1150px) {
      gap: 0.5rem;
    }

    @include desktopLarge {
      gap: 0.75rem;
      margin-top: 0.25rem;
    }

    &:first-of-type {
      flex-grow: 1;
    }
  }

  .main-navigation * {
    font-family: $museo-700;
    font-size: 12px;

    @media screen and (min-width: 1150px) {
      font-size: 14px;
    }

    @include desktopLarge {
      font-size: 16px;
    }
  }

  .utility-navigation * {
    font-family: $museo-300;
    font-size: 11px;

    @media screen and (min-width: 1150px) {
      font-size: 13px;
    }

    @include desktopLarge {
      font-size: 15px;
    }
  }

  .wrapper {
    align-items: center;
    display: inline-flex;
    gap: 0.25rem;
    height: 100%;
    position: relative;
    z-index: 2;

    &:has(.link-icon) {
      gap: 0.5rem;
    }

    & span {
      line-height: 20px;
    }
  }

  .fill-animation {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 9999px;
    color: currentcolor;
    display: flex;
    overflow: hidden;
    padding: 0.25rem 0.5rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 3;

    @include desktopLarge {
      padding: 0.5rem 1rem;
    }

    &::before {
      aspect-ratio: 1;
      background: $main-pink;
      border-radius: 50%;
      content: '';
      left: var(--x);
      pointer-events: none;
      position: absolute;
      top: var(--y);
      transform: translate(-50%, -50%);
      transition:
        width 500ms,
        height 500ms;
      width: 0;
    }

    &:hover::before,
    &:focus::before {
      width: 200%;
    }

    &:focus-within {
      outline: 2px $white solid;
    }
  }

  .navigation-panel {
    background: rgb(45 45 75 / 0.8);
    border-bottom-left-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
    bottom: 100%;
    left: 0;
    padding: 2rem 10px;
    position: absolute;
    transition: all 500ms 0ms;
    width: 100%;
    z-index: -1;

    &[data-is-open='true'] {
      bottom: 0;
      transform: translateY(100%);
    }

    .wrapper {
      display: grid;
      gap: 1.25rem;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      margin-inline: auto;
      max-width: 1600px;
    }
  }

  .navigation-panel-overlay {
    background: $main;
    height: 100%;
    left: 0;
    position: absolute;
    top: -100%;
    transition: top 0ms 500ms;
    width: 100%;
    z-index: 2;

    &[data-is-open='true'] {
      top: 0;
      transition: top 0ms;
    }
  }

  .navigation-panel-item {
    align-items: start;
    background-color: $main-pink;
    border-radius: 1.125rem;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    padding: 1.5rem;
    position: relative;
    text-decoration: none;
    transition:
      all 150ms ease-in-out,
      bottom 150ms 150ms ease-in-out;
    will-change: background, color, transform, bottom;

    &:hover,
    &:focus {
      background: $white;
      color: $black;
      transform: translateY(-0.5rem);

      img {
        filter: brightness(0);
      }
    }

    img {
      display: inline-block;
      filter: brightness(0) invert(1);
      height: 4rem;
      width: 4rem;

      @include desktop {
        height: 6rem;
        width: 6rem;
      }
    }

    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-block: 0;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-block: 0;
    }
  }
}

.mobile-navigation {
  isolation: isolate;

  @include desktop {
    display: none;
  }

  .navigation-bar {
    align-items: center;
    background-color: $main;
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
    z-index: 1;

    .main-logo {
      border-radius: 9999px;
      display: flex;
      overflow: hidden;
      padding: 0.5rem 1rem;

      &:focus {
        outline: 2px $white solid;
      }
    }

    .icons {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      gap: 20px;
      justify-content: flex-end;

      * {
        appearance: none;
        background: transparent;
        border: none;
        height: 20px;
        padding: 0;
        width: 20px;

        &:focus,
        &:focus-within {
          border-radius: 2px;
          outline: 2px solid $white;
          outline-offset: 2px;
        }
      }
    }
  }

  .navigation-dropdown {
    background-color: $main;
    display: flex;
    flex-direction: column;
    gap: 32px;
    left: 0;
    max-height: var(--max-height);
    padding: 20px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 300ms ease-in-out;
    width: 100%;
    z-index: -1;

    &[data-is-open='true'] {
      transform: translateY(0);
    }
  }

  .navigation-dropdown-overlay {
    background: rgb(45 45 75 / 0.8);
    height: 100dvh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 300ms ease-in-out;
    width: 100%;
    z-index: -2;

    &[data-is-open='true'] {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .navigation-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    > li {
      width: 100%;
    }
  }

  .navigation-button {
    appearance: none;
    background: transparent;
    border: none;
    display: flex;
    padding-inline: 0;
    width: 100%;

    .wrapper {
      align-items: center;
      align-self: stretch;
      display: flex;
      gap: 4px;
      padding: 6px 0;
    }

    .dropdown-caret {
      align-items: center;
      display: flex;
      height: 16px;
      justify-content: center;
    }
  }

  .navigation-link {
    display: flex;
    padding: 6px 0;
    text-decoration: none;

    .wrapper {
      align-items: center;
      display: flex;
      gap: 12px;
    }
  }

  .navigation-button span,
  .navigation-link span {
    color: $white;
    flex: 1 0 0;
    font-family: $museo-700;
    font-size: 20px;
    letter-spacing: 0.6px;
    line-height: 20px;
    text-align: start;
    text-transform: uppercase;
  }

  .navigation-button,
  .navigation-link {
    &:focus,
    &:focus-within {
      border-radius: 2px;
      outline: 2px solid $white;
      outline-offset: 8px;
    }
  }

  .navigation-button[data-is-open='true'] {
    border-bottom: 1px solid $main-yellow;
  }

  .utility-navigation .navigation-link span {
    font-family: $museo-500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 16px;
    text-transform: none;
  }

  .navigation-panel {
    margin-top: 0;
    max-height: 0;
    position: relative;
    transition:
      max-height 150ms 150ms ease-in-out,
      margin-top 150ms 150ms ease-in-out;
    visibility: hidden;

    &[data-is-open='true'] {
      margin-top: 20px;
      max-height: 100dvh;
      transition:
        max-height 300ms ease-in-out,
        margin-top 0ms;
      visibility: visible;

      .navigation-panel-item {
        opacity: 1;
        right: 0;
        transition:
          right 150ms 250ms ease-in-out,
          opacity 150ms 250ms ease-in-out;

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: 50ms * $i;
          }
        }
      }
    }

    .wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > * {
        width: 100%;
      }
    }
  }

  .navigation-panel-item {
    align-items: start;
    background-color: $main-pink;
    border-radius: 24px;
    color: $white;
    cursor: pointer;
    display: grid;
    gap: 6px 16px;
    grid-template:
      'icon title' auto
      'icon description' auto / 60px auto;
    height: 100%;
    opacity: 0;
    padding: 24px;
    position: relative;
    right: 80px;
    text-decoration: none;
    transition: all 150ms ease-in-out;
    will-change: background, color, transform, right, opacity;

    &:hover,
    &:focus {
      background: $white;
      color: $black;

      img {
        filter: brightness(0);
      }
    }

    img {
      display: inline-block;
      filter: brightness(0) invert(1);
      grid-area: icon;
      height: 60px;
      width: 60px;
    }

    h3 {
      font-family: $museo-700;
      font-size: 21px;
      grid-area: title;
      grid-column: 2;
      grid-row: 1;
      line-height: 24px;
      margin-block: 0;
    }

    p {
      font-family: $museo-500;
      font-size: 16px;
      grid-area: description;
      grid-column: 2;
      grid-row: 2;
      line-height: 18px;
      margin-block: 0;
    }
  }
}
