.scroll-container {
  --container-height: 100%;
  --container-width: 600px;
  --block-height: 600px;

  height: var(--container-height);

  &-wrapper {
    height: var(--block-height);
    position: sticky;
    top: calc(50% - var(--block-height) / 2 + 40px);
  }

  &-horizontal-scroll {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 100%;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: var(--container-width);
    will-change: transform;
  }
}
