.footer-links {
  background-color: $primary-dark;
  color: $white;
  padding: 40px 0;

  ul {
    li {
      border-bottom: none;

      a,
      span {
        padding: 0;
      }
    }
  }

  .subfooter {
    a {
      @include fontSize(14px);

      color: $white;
    }
  }
}
