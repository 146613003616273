.tile-expansion {
  background-color: $white;
  border-radius: 25px;

  @include desktop {
    border-radius: 50px;
    display: flex;
    margin-top: 30px;
  }

  a {
    display: table;
    margin: 15px 0;
  }

  h3 {
    display: inline-block;
    line-height: 36px;
    margin: 0;
    text-transform: uppercase;
    vertical-align: middle;
  }

  h4 {
    &.newsroom-title {
      margin-top: 10px;
      text-transform: none;
    }
  }

  img {
    &.small-icon {
      height: 36px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .inner-content {
    color: $black;

    a {
      display: inline-block;
    }

    @include deviceOnly {
      padding: 25px;
    }
  }

  .large-column {
    margin-right: 100px;
  }

  .small-column,
  .large-column {
    margin-top: 40px;

    @include desktop {
      margin-top: 30px;
    }
  }

  .small-column {
    @include desktop {
      padding-left: 10px;
    }
  }

  .heading {
    margin-top: 40px;

    @include desktop {
      &:first-of-type {
        margin-top: 0;
      }

      margin-top: 70px;
    }
  }
}

.section-offset .tile-expansion {
  background-color: unset;
}

.section-offset .tile .circle::after {
  border-color: $offset;
}

.section-white .tile.contains-link a,
.section-offset .tile.contains-link a {
  color: $black;
}

.tile {
  border-radius: 55px 55px 50px 50px;
  transition: background-color 0.1s ease-out;

  @include deviceOnly {
    border-radius: 25px;
  }

  .content {
    border-radius: 50px;
    padding: 24px 20px 19px;
    text-align: center;
    transition: background-color 0.1s ease-out;

    @include deviceOnly {
      padding-bottom: 0;
    }

    p {
      font-family: $sans-medium;
      margin-top: 10px;
      text-transform: none;
    }

    h3 {
      margin: 0;
      text-transform: uppercase;
    }

    h3,
    p {
      line-height: 20px;
    }

    &.active {
      h3,
      p {
        color: $white;

        @include deviceOnly {
          color: $black;
        }
      }
    }
  }

  &.active {
    background-color: $primary-dark;

    .content {
      h3,
      p {
        color: $white;
      }
    }
  }

  &-container {
    border-radius: 25px 50%;
    height: 100%;
    position: relative;
  }

  button {
    color: unset;
    line-height: 0;
  }

  img {
    border-radius: 50px;
    width: 100%;

    @include deviceOnly {
      border-radius: 25px;
    }
  }

  .tile-expansion {
    button {
      line-height: 20px;
    }
  }

  &:hover {
    transition: background-color 0.1s ease-out;

    @include tabletAndDesktop {
      background-color: $primary-dark;

      .content {
        h3,
        p {
          color: $white;
        }
      }
    }
  }
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  text-align: center;

  .tile {
    flex: 0 0 100%;

    @include tablet {
      flex: 0 0 calc(50% - 20px);
    }

    @include tabletAndDesktop {
      margin: 10px;
    }

    @include desktop {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 10px;
    }

    .btn-no-style {
      @include mobile {
        margin-bottom: 30px;
        max-width: 460px;
      }
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.ReactModal__Content {
  .tile {
    background: transparent;
  }
}
