.card {
  display: flex;
  flex: 0 0 100%;
  margin-bottom: 55px;

  @include mobile {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include tablet {
    flex: 0 0 calc(50% - 10px);
  }

  @include desktop {
    flex: 0 0 25%;

    &.featured {
      flex-grow: 1;
      flex-wrap: wrap;
    }
  }

  a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
  }

  .content {
    flex: 0 1 100%;
    margin: 0 10px;

    .inner-image {
      line-height: 0;

      img {
        width: 100%;
      }

      &.no-thumbnail {
        align-items: center;
        background-color: $primary-dark;
        background-image: url('../images/logo-inverse.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 164px;
        display: flex;
        justify-content: center;
        padding-top: 57.222%;
      }
    }

    .inner-content {
      margin-top: 20px;
      text-transform: none;

      .tag {
        @include fontSize(12px);

        background-color: $gray-light;
        color: $gray-dark;
        font-family: $sans-medium;
        padding: 3px 6px;
      }

      .title {
        color: $black !important;
        font-family: $sans-bold;
        margin: 5px 0;
      }
    }
  }

  .section-header {
    color: $gray-dark;
    cursor: pointer;

    &.top {
      font-family: $sans-bold;
      padding-bottom: 10px;
      text-decoration: none;
    }

    &.link {
      text-decoration: underline;
    }
  }

  // Featured Card overrides
  &.featured {
    margin-bottom: 0;

    .content {
      background-color: $white;
      border-radius: 28px 28px 25px 25px;

      @include desktop {
        border-radius: 55px 55px 50px 50px;
      }

      img {
        border-radius: 25px 25px 0 0;

        @include desktop {
          border-radius: 50px 50px 0 0;
        }
      }

      .inner-content {
        padding: 0 20px 20px;
        text-align: center;

        .section-header {
          color: $gray-dark;

          &.link {
            color: $text-link;
          }
        }
      }
    }
  }
}

.section-white .card.featured .content {
  background-color: $offset;
}
