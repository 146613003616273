.section-products-services {
  position: relative;

  .close-button {
    margin-right: 0;
    min-width: 25px;
    right: 16px;
    top: 16px;
  }

  .tile {
    &-container button {
      align-items: start;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &:not(.active) .circle {
      visibility: hidden;
    }
  }

  .content {
    @include desktop {
      display: flex;
      justify-content: space-between;
    }

    &-left {
      @include desktop {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      }

      h2 {
        margin: 0 0 20px;
      }

      p {
        margin-bottom: 40px;
      }
    }

    &-right {
      align-self: flex-end;
      display: none;
      text-align: right;

      @include desktop {
        display: block;
      }

      img {
        height: 25px;
        margin-bottom: 2px;
      }
    }
  }

  .slick-list {
    padding-bottom: 9px;
  }

  .slick-track {
    margin-bottom: 30px;

    @include desktop {
      display: flex;
      width: 100% !important;
    }

    &::before {
      display: none;
    }

    .slick-slide {
      @include desktop {
        display: flex;
        flex: 1 0 25%;
        height: auto;
        transition: background-color 0.1s ease-out;

        > div {
          display: flex;
          flex: 100%;
          padding: 0 10px;
        }

        .tile {
          margin: 0;
        }

        &:hover {
          .content {
            h3,
            p {
              color: $white;
            }
          }
        }
      }
    }
  }

  .tile-expansion {
    margin: 0 45px 10px;
    position: relative;
    z-index: 10;

    h3 {
      display: block;
      margin-bottom: 20px;
    }

    .btn-no-style {
      color: $primary;

      &:hover,
      &.active {
        color: $main;
      }
    }

    .content {
      flex-grow: 1;
      flex-wrap: wrap;

      .eyebrow-text {
        @include fontSize(20px);

        display: block;
        line-height: 36px;
        margin-bottom: 10px;
      }

      .navigation {
        margin-top: 60px;
        position: relative;

        .button {
          position: absolute;
          width: auto;

          &:hover {
            button {
              color: $primary;
              text-decoration: underline;
            }
          }

          h3 {
            @include fontSize(16px);
          }

          img {
            height: 15px;
            margin: 0 10px 0 0;
            vertical-align: -2px;
          }

          &.next {
            right: 0;

            button {
              text-align: right;
            }

            img {
              margin: 0 0 0 10px;
            }
          }
        }
      }

      .item,
      .navigation {
        flex: 0 0 100%;
        padding: 30px 40px;
      }
    }

    &.no-slider {
      margin: 38px 0 10px;
    }

    .sidebar-nav {
      flex: 0 0 35%;
    }
  }

  .tiles {
    margin-top: 30px;

    @include desktop {
      display: block;
      margin: 30px 40px 0;
      position: relative;

      &.no-slider {
        display: flex;
        margin: 30px -10px 0;
      }

      .navigation {
        align-items: center;
        display: flex;
        height: 100%;
        position: absolute;
        top: 0;
        width: auto;

        &#next-arrow {
          right: -40px;
        }

        &#previous-arrow {
          left: -40px;
        }

        img {
          height: 45px;
        }
      }
    }

    .tile {
      a {
        text-decoration: none;
      }

      .content {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
