.util {
  .filters {
    @include desktop {
      box-shadow: 0 4px 4px 0 #00000026;
    }

    &-container {
      display: flex;

      &.border-top {
        @include desktop {
          border-top: 2px solid $gray-light;
        }
      }

      @include deviceOnly {
        margin: 0 -10px;

        > div {
          flex: 1 1 50%;
        }
      }

      @include desktop {
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 50px 0;

        ul {
          flex: 0 1 75%;
        }
      }

      .accordion-list {
        margin: 0;

        p {
          margin: 1rem 10px;
        }

        @include desktop {
          flex: 0 0 25%;
          margin: 0 0 0 auto;
          position: relative;
        }
      }
    }

    &-list {
      border-bottom: 1px solid $gray;
      padding: 15px 0 15px 40px;

      @include desktop {
        border-bottom: none;
        display: flex;
        flex-wrap: wrap;
        padding: 40px 0;

        > .form-group {
          flex: 1 0 20%;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .reset {
        flex: 0 0 100%;
        text-align: center;

        @include desktop {
          text-align: right;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;

        &.block {
          display: block;
        }

        &:first-of-type {
          button {
            margin-left: 0;
          }
        }

        &:last-of-type {
          button {
            margin-right: 0;
          }
        }
      }

      &.accordion {
        li {
          border: 1px solid $gray;
          width: 100%;

          img.chevron {
            top: 18px;
          }

          span {
            padding: 10px 20px;
          }
        }
      }
    }
  }

  .expanded-filters-list {
    @include deviceOnly {
      background-color: $gray-light;
      position: absolute;
      width: 100%;
      z-index: 20;
    }

    &.dropdown {
      left: 0;

      ul li {
        display: block;

        button {
          background: transparent;
          border: none;
          color: $primary;
          margin: 5px 0;
          padding: 0;
          text-transform: inherit;
          transition: background 0.2s ease;
        }
      }
    }

    @include desktop {
      box-shadow: 0 4px 4px 0 #00000026;

      &.dropdown {
        background-color: $gray-light;
        box-shadow: none;
        position: absolute;
        width: 100%;

        .filters-list {
          border: 1px solid $gray;
          border-top: none;
          padding: 20px;
        }
      }
    }
  }
}

.count {
  padding-top: 20px;

  @include desktop {
    padding-top: 40px;
  }

  .section-header {
    @include fontSize(20px);

    text-transform: inherit;
  }
}

.sub-filters {
  border-bottom: 1px solid $gray;
  display: flex;

  .tab {
    cursor: pointer;

    h3 {
      @include fontSize(18px);

      color: $primary;
      margin: 0;
      text-transform: uppercase;
    }

    .btn-no-style {
      color: $primary;
      padding: 12px 50px;
    }

    &:hover,
    &.active {
      background-color: $primary;

      .btn-no-style {
        color: $white;
      }
    }
  }
}
