.general-text-component {
  .description-container {
    margin-top: 15px;

    @include desktop {
      display: flex;

      .content {
        flex: 1 0 58.33333%;
      }

      &.left {
        .image {
          margin-right: 30px;
          order: 1;
        }

        .content {
          order: 2;
        }
      }

      &.right {
        .image {
          margin-left: 10px;
        }
      }
    }
  }

  .image {
    &:has(.restrict-height) {
      display: grid;
      flex-basis: 41.66667%;
      flex-shrink: 0;
      place-items: center;
    }

    img {
      border-radius: 50px;
    }

    img.restrict-height {
      max-height: 293px;
      width: auto;
    }

    @include deviceOnly {
      text-align: center;

      img {
        border-radius: 25px;
      }
    }
  }
}
