.newsroom-detail {
  // Hero override
  .hero-small {
    .inner-content {
      width: 100%;
    }
  }

  .content {
    @include tabletAndDesktop {
      display: flex;
      justify-content: space-between;

      .main-content {
        flex: 0 0 66.66667%;
      }

      .sidebar {
        flex: 0 0 25%;
      }
    }

    .main-content {
      .inner-content {
        margin-top: 30px;

        img {
          height: auto !important;
        }
      }
    }

    .published-by {
      font-family: $sans-medium-italic;
      font-weight: 600;
    }

    .sidebar {
      .author {
        img {
          border: 3px solid $main;
          border-radius: 45px;
          height: 90px;
          width: 90px;
        }
      }

      .section {
        margin-top: 60px;

        &-header {
          @include fontSize(20px);

          line-height: 24px;
        }

        @include tabletAndDesktop {
          &:first-of-type {
            margin-top: 0;
          }
        }
      }

      .share-button-wrapper {
        --background-image: '';

        display: inline-block;
        margin: 0 10px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      .share-button {
        background: var(--background-image);
        background-size: cover;
        cursor: pointer;
        height: 40px;
        width: 40px;
      }

      .tags {
        a {
          display: block;
          margin-bottom: 15px;
        }
      }
    }
  }
}
